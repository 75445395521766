import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
      FlightInfoContainer,
      FlightInfoContent,
      FlightInfoDuration,
      FlightInfoLogo,
      FlightInfoMultipleLogo,
      FlightInfoPrice,
      FlightInfoTime,
      FlightInfoTitle,
} from "../../../Dashboard/FlightInfo/styles";
import FlightTripPrice from "../FlightTripPrice";
import { FlightLayoutPrice } from "./style";
import { FlightTripDetailButton } from "../FlightTripPrice/style";
import FlightTripAccordion from "../FlightTripAccordion";
import { convertAmount, convertFloat } from "../../../../hooks/CurrentData";

const FlightLayout = ({
      item,
      netFare,
      fareId,
      setFareId,
      searchDetail,
      fullFare,
      flights,
      returnStatus,
}) => {
      const [status, setStatus] = useState(false);
      const [moreFare, setMoreFare] = useState(false);

      const checkCabinClassPrice = () => {
            let filteredData = item?.totalPriceList?.filter(
                  (item) => item?.fd?.ADULT?.cc === searchDetail?.cabinClass
            );

            return filteredData.sort(
                  (a, b) => a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF
            );
      };

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const checkPrice = (prices) => {
            const newPrice = prices.find(
                  (newItem) => newItem?.id === fareId?.id
            );
            if (newPrice) {
                  return newPrice;
            }
      };

      const calculateTotalAmount = (currentItem) => {
            let newAmount = 0;
            if (currentItem?.ADULT) {
                  newAmount =
                        newAmount +
                        currentItem?.ADULT?.fC?.TF *
                              (fullFare ? searchDetail?.adult : 1);
            }

            if (fullFare) {
                  if (currentItem?.CHILD) {
                        newAmount =
                              newAmount +
                              currentItem?.CHILD?.fC?.TF *
                                    (fullFare ? searchDetail?.children : 1);
                  }
                  if (currentItem?.INFANT) {
                        newAmount =
                              newAmount +
                              currentItem?.INFANT?.fC?.TF *
                                    (fullFare ? searchDetail?.infants : 1);
                  }
            }
            return newAmount;
      };

      const calculateTotalNetAmount = (currentItem) => {
            let newAmount = 0;
            if (currentItem?.ADULT) {
                  newAmount =
                        newAmount +
                        currentItem?.ADULT?.fC?.NF *
                              (fullFare ? searchDetail?.adult : 1);
            }

            if (fullFare) {
                  if (currentItem?.CHILD) {
                        newAmount =
                              newAmount +
                              currentItem?.CHILD?.fC?.NF *
                                    (fullFare ? searchDetail?.children : 1);
                  }
                  if (currentItem?.INFANT) {
                        newAmount =
                              newAmount +
                              currentItem?.INFANT?.fC?.NF *
                                    (fullFare ? searchDetail?.infants : 1);
                  }
            }
            return newAmount;
      };

      const checkAirline = (sI) => {
            if (sI.length === 0) return false;

            const firstAirlineCode = sI[0].fD.aI.code;
            return sI.every(
                  (segment) => segment.fD.aI.code === firstAirlineCode
            );
      };

      const calculateTotalTime = (isoString1, isoString2) => {
            const date1 = new Date(isoString1);
            const date2 = new Date(isoString2);

            const differenceInMs = Math.abs(date1 - date2);

            const totalMinutes = Math.floor(differenceInMs / 60000);

            return totalMinutes;
      };

      const checkTime = (items) => {
            let totalTime = 0;

            items?.sI?.map((sI, index) => {
                  totalTime = totalTime + sI?.duration;
                  if (items?.sI?.length > 1 && items?.sI.length !== index + 1) {
                        totalTime =
                              totalTime +
                              calculateTotalTime(
                                    sI?.at,
                                    items?.sI[index + 1]?.dt
                              );
                  }
            });

            const hours = calculateDuration(totalTime).hours;
            const minutes = calculateDuration(totalTime).minutes;

            return { hours, minutes };
      };

      useEffect(() => {
            setStatus(false);
      }, [flights]);

      return (
            <>
                  <Form>
                        <FlightInfoContainer padding="12px">
                              <FlightInfoContent>
                                    <FlightInfoTitle className="gap-2">
                                          {checkAirline(item?.sI) ? (
                                                <FlightInfoLogo>
                                                      <img
                                                            src={`/images/AirlinesLogo/${item?.sI[0]?.fD?.aI?.code}.png`}
                                                            alt={
                                                                  item?.sI[0]
                                                                        ?.fD?.aI
                                                                        ?.name
                                                            }
                                                      />
                                                </FlightInfoLogo>
                                          ) : (
                                                <FlightInfoMultipleLogo>
                                                      {item?.sI?.map(
                                                            (image, key) =>
                                                                  image?.fD?.aI
                                                                        ?.code !==
                                                                        item
                                                                              ?.sI[
                                                                              key +
                                                                                    1
                                                                        ]?.fD
                                                                              ?.aI
                                                                              ?.code && (
                                                                        <img
                                                                              src={`/images/AirlinesLogo/${image?.fD?.aI?.code}.png`}
                                                                              alt={
                                                                                    image
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        />
                                                                  )
                                                      )}
                                                </FlightInfoMultipleLogo>
                                          )}
                                          <div className="title">
                                                <h4 className="pb-0 mb-0">
                                                      {
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.name
                                                      }
                                                </h4>
                                                <div
                                                      style={{
                                                            fontSize: "11px",
                                                            color: "#666",
                                                      }}
                                                >
                                                      {
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.code
                                                      }{" "}
                                                      {item?.sI[0]?.fD?.fN}
                                                </div>
                                          </div>
                                    </FlightInfoTitle>
                                    <FlightInfoTime>
                                          <FlightInfoTime>
                                                <div>
                                                      {formatTime(
                                                            item?.sI[0]?.dt
                                                      )}
                                                </div>
                                          </FlightInfoTime>

                                          <div
                                                className="place d-flex gap-1 align-items-center"
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {`${item?.sI[0]?.da?.city} (${item?.sI[0]?.da?.cityCode})`}
                                          </div>
                                    </FlightInfoTime>
                                    <FlightInfoDuration fontWeight="500">
                                          <div>
                                                {`${checkTime(item)?.hours}h ${
                                                      checkTime(item)?.minutes
                                                }m`}
                                          </div>

                                          <div
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {item?.sI?.length === 1
                                                      ? "Non-stop"
                                                      : "1 Stop(s)"}
                                          </div>
                                    </FlightInfoDuration>
                                    <FlightInfoTime>
                                          <FlightInfoTime>
                                                <div>
                                                      {formatTime(
                                                            item?.sI[
                                                                  item?.sI
                                                                        ?.length -
                                                                        1
                                                            ]?.at
                                                      )}
                                                </div>
                                          </FlightInfoTime>

                                          <div
                                                className="place d-flex gap-1 align-items-center"
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {`${
                                                      item?.sI[
                                                            item?.sI?.length - 1
                                                      ]?.aa?.city
                                                } (${
                                                      item?.sI[
                                                            item?.sI?.length - 1
                                                      ]?.aa?.cityCode
                                                })`}
                                          </div>
                                    </FlightInfoTime>
                                    <FlightInfoPrice>
                                          <div className="flight__info-price">
                                                <span>
                                                      {`₹ ${
                                                            checkPrice(
                                                                  checkCabinClassPrice()
                                                            )
                                                                  ? convertAmount(
                                                                          convertFloat(
                                                                                calculateTotalAmount(
                                                                                      checkPrice(
                                                                                            checkCabinClassPrice()
                                                                                      )
                                                                                            ?.fd
                                                                                )
                                                                          )
                                                                    )
                                                                  : convertAmount(
                                                                          convertFloat(
                                                                                calculateTotalAmount(
                                                                                      checkCabinClassPrice()[0]
                                                                                            ?.fd
                                                                                )
                                                                          )
                                                                    )
                                                      }`}
                                                </span>
                                          </div>
                                          {netFare && (
                                                <span className="flight__info-net text-muted">{` NET ₹ ${
                                                      checkPrice(
                                                            checkCabinClassPrice()
                                                      )
                                                            ? convertAmount(
                                                                    convertFloat(
                                                                          calculateTotalNetAmount(
                                                                                checkPrice(
                                                                                      checkCabinClassPrice()
                                                                                )
                                                                                      ?.fd
                                                                          )
                                                                    )
                                                              )
                                                            : convertAmount(
                                                                    convertFloat(
                                                                          calculateTotalNetAmount(
                                                                                checkCabinClassPrice()[0]
                                                                                      ?.fd
                                                                          )
                                                                    )
                                                              )
                                                }`}</span>
                                          )}
                                          {!netFare &&
                                                checkCabinClassPrice()[0]?.fd
                                                      ?.ADULT?.sR <= 5 && (
                                                      <div
                                                            style={{
                                                                  fontSize: "11px",
                                                                  color: "#666",
                                                            }}
                                                            className="text-danger"
                                                      >
                                                            {`${fareId?.fd?.ADULT?.sR} Seats left`}
                                                      </div>
                                                )}
                                    </FlightInfoPrice>
                              </FlightInfoContent>

                              <FlightTripPrice
                                    totalPriceList={item?.totalPriceList}
                                    setFareId={setFareId}
                                    returnStatus={true}
                                    fareId={fareId}
                                    moreFare={moreFare}
                                    searchDetail={searchDetail}
                                    netFare={netFare}
                                    fullFare={fullFare}
                              />
                              <FlightLayoutPrice>
                                    {item?.totalPriceList?.length > 2 && (
                                          <FlightTripDetailButton
                                                fontSize="12px"
                                                onClick={() =>
                                                      setMoreFare(!moreFare)
                                                }
                                                type="button"
                                                className={`${
                                                      moreFare ? "active" : ""
                                                }`}
                                          >
                                                {!moreFare ? "More" : "Hide"}{" "}
                                                Fare
                                          </FlightTripDetailButton>
                                    )}

                                    <FlightTripDetailButton
                                          onClick={() => setStatus(!status)}
                                          type="button"
                                          className={`${
                                                status ? "active" : ""
                                          }`}
                                    >
                                          Flight Details
                                    </FlightTripDetailButton>
                              </FlightLayoutPrice>
                              {status && (
                                    <div className="bg-gray w-100">
                                          <FlightTripAccordion
                                                fareId={
                                                      checkPrice(
                                                            checkCabinClassPrice()
                                                      )
                                                            ? checkPrice(
                                                                    checkCabinClassPrice()
                                                              )
                                                            : checkCabinClassPrice()[0]
                                                }
                                                items={item}
                                                setFareId={setFareId}
                                                searchDetail={searchDetail}
                                                returnStatus={returnStatus}
                                          />
                                    </div>
                              )}
                        </FlightInfoContainer>
                  </Form>
            </>
      );
};

export default FlightLayout;
