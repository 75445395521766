import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const FlightSeatList = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed ${(props) => props.theme.primary};
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 20px;

      &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
      }
`;

export const FlightModal = styled(Modal)`
      @media (min-width: 992px) {
            .modal-lg,
            .modal-xl {
                  --bs-modal-width: 1000px;
            }
      }
      .modal-body {
            position: relative;
            height: 90vh;
            overflow: auto;
      }

      .btn-close {
            position: absolute;
            cursor: pointer;
            top: 10px;
            right: 20px;
            z-index: 1;
            padding: 10px;
            border-radius: 50rem;
            transition: all 0.3s ease-in;
            background-color: #f4f4f4;
            border: 1px solid #e5e3e3;

            &:focus {
                  box-shadow: none;
            }

            &:hover {
                  background-color: ${(props) => props.theme.primary};
                  border: 1px solid #e5e3e3;
                  opacity: 1;
                  --bs-btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
            }
      }
`;

export const FlightModalBody = styled.div`
      height: calc(100% - 80px);
      overflow: hidden;
`;

export const FlightModalFooter = styled.div`
      position: relative;
      height: 80px;

      &::after {
            content: "";
            position: absolute;
            top: -34px;
            left: 16px;
            width: calc(100% - 16px - 16px);
            height: 34px;
            background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
            pointer-events: none;
      }
`;

export const TravellerButton = styled.button`
      border: 0;
      color: ${(props) => props.theme.white};
      background: ${(props) => props.theme.primary};
      padding: 5px 20px;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;

export const FlightRow = styled.div`
      display: flex;
      margin-bottom: 5px;
`;

export const FlightSeatContainer = styled.div`
      display: flex;
      justify-content: center;
      overflow: auto;
      border-left: 1px dashed ${(props) => props.theme.primary};
`;

export const FlightSeatContent = styled.div``;

export const FlightSeat = styled.div`
      min-width: ${(props) => props.width || "42px"};
      min-height: ${(props) => props.height || "42px"};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      font-size: 12px;
      color: ${(props) => props.theme.primary};
      border: 1px solid ${(props) => props.theme.primary};
      border-radius: 14px;
      background-color: transparent;
      cursor: pointer;
      transition: all 0.3s ease-in;
      position: relative;
      overflow: hidden;

      &.bg-0 {
            background-color: ${(props) => props.theme.white};
      }

      &.bg-1 {
            background-color: #e9c352;
            border-color: #e9c352;
            color: ${(props) => props.theme.white};
      }

      &.bg-2 {
            background-color: #e69c77;
            border-color: #e69c77;
            color: ${(props) => props.theme.white};
      }

      &.bg-3 {
            background-color: #98d0be;
            border-color: #98d0be;
            color: ${(props) => props.theme.white};
      }

      &.bg-4 {
            background-color: #b589d7;
            border-color: #b589d7;
            color: ${(props) => props.theme.white};
      }

      &.bg-5 {
            background-color: #b375b3;
            border-color: #b375b3;
            color: ${(props) => props.theme.white};
      }

      &:hover,
      &.active {
            background-color: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.white};
            border-color: ${(props) => props.theme.primary};
      }

      &.booked {
            cursor: not-allowed;
            border-color: #8e9195;
            background-color: #f5f5f5;

            &::before {
                  content: "";
                  position: absolute;
                  top: -52.5%;
                  width: 1px;
                  height: 84px;
                  background-color: #8e9195;
                  transform: rotate(45deg);
                  right: calc(50% + 1px);
            }

            &::after {
                  transform: rotate(-45deg);
                  left: calc(50% + 1px);
                  content: "";
                  position: absolute;
                  top: -52.5%;
                  width: 1px;
                  height: 84px;
                  background-color: #8e9195;
            }
      }
`;

export const FlightPriceList = styled.ul`
      margin: 0;
      list-style: none;
      display: flex;
      padding: 0;
      align-items: center;
      gap: 10px;
      padding: 10px;
      /* padding-top: 20px; */

      li {
            display: flex;
            align-items: center;

            div {
                  font-size: 14px !important;

                  &.bg-selected {
                        background-color: ${(props) => props.theme.primary};
                  }

                  &.bg-0 {
                        background-color: ${(props) => props.theme.white};
                  }

                  &.bg-1 {
                        background-color: #e9c352;
                        border-color: #e9c352;
                        color: ${(props) => props.theme.white};
                  }

                  &.bg-2 {
                        background-color: #e69c77;
                        border-color: #e69c77;
                        color: ${(props) => props.theme.white};
                  }

                  &.bg-3 {
                        background-color: #98d0be;
                        border-color: #98d0be;
                        color: ${(props) => props.theme.white};
                  }

                  &.bg-4 {
                        background-color: #b589d7;
                        border-color: #b589d7;
                        color: ${(props) => props.theme.white};
                  }

                  &.bg-5 {
                        background-color: #b375b3;
                        border-color: #b375b3;
                        color: ${(props) => props.theme.white};
                  }

                  &:hover {
                        color: ${(props) => props.theme.primary};
                  }

                  &.booked {
                        cursor: not-allowed;
                        border-color: #8e9195;
                        background-color: #f5f5f5;
                        position: relative;

                        &::before {
                              content: "";
                              position: absolute;
                              top: -52.5%;
                              width: 1px;
                              height: 40px;
                              background-color: #8e9195;
                              transform: rotate(45deg);
                              right: calc(50% + 1px);
                        }

                        &::after {
                              transform: rotate(-45deg);
                              left: calc(50% + 1px);
                              content: "";
                              position: absolute;
                              top: -52.5%;
                              width: 1px;
                              height: 40px;
                              background-color: #8e9195;
                        }
                  }
            }
      }
`;

export const SeatAirlineContainer = styled.div`
      display: flex;
      gap: 10px;
      align-items: center;
`;

export const SeatAirlineBorder = styled.div`
      margin-bottom: 1rem;
      border-bottom: 1px solid ${(props) => props.theme.primary};
      padding-bottom: 1rem;
`;

export const SeatAirlineInfo = styled.div`
      display: flex;
      gap: 10px;
`;

export const SeatAirlineName = styled.div`
      font-size: 16px;
      color: ${(props) => props.theme.primary};
      font-weight: 600;
`;

export const SeatAirlineSubtitle = styled.div`
      font-size: 14px;
`;

export const SeatAirlineImage = styled.div`
      height: 40px;
      width: 40px;

      img {
            height: 100%;
            width: 100%;
      }
`;

export const SeatPriceContainer = styled.div`
      padding-right: 20px;
`;

export const SeatPriceContent = styled.ul`
      margin: 0;
      list-style: none;
      padding: 0;

      li {
            display: flex;
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px dashed ${(props) => props.theme.primary};
            padding-top: 10px;
            cursor: pointer;

            &:first-child {
                  background-color: transparent;
                  padding-top: 0;

                  div {
                        font-weight: 600;
                  }
            }

            &.active {
                  background-color: ${props => props.theme.primary};
                  color: ${props => props.theme.white};
            }

            div {
                  font-size: 14px;
            }

            div:nth-child(1) {
                  width: 55%;
                  padding-left: 5px;
            }

            div:nth-child(2) {
                  min-width: 20%;
            }

            div:nth-child(3) {
                  min-width: 25%;
            }

            &:last-child {
                  border-bottom: 0;
                  background-color: transparent;

                  div {
                        font-weight: 600;
                  }
            }
      }
`;
