import React, { useEffect, useState } from "react";
import RangeSliderInput from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import {
      InputSliderContainer,
      MaxValueBox,
      MinValueBox,
      PriceRange,
} from "./styles";
import {
      DashboardListContainer,
      DashboardListTitle,
} from "../../Dashboard/DashboardList/styles";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";

const InputSlider = ({ flights, searchDetail, range, setRange }) => {
      const minPrice = Math.min(
            ...flights
                  ?.map((flight) => {
                        // Extract the minimum price for each flight
                        return Math.min(
                              ...flight.totalPriceList
                                    .filter(
                                          (price) =>
                                                price.fd.ADULT.cc ===
                                                searchDetail?.cabinClass // Filter based on cabin class
                                    )
                                    .map(
                                          (price) =>
                                                price?.fd?.ADULT?.fC?.TF ||
                                                Infinity // Map to TF or Infinity if not available
                                    )
                        );
                  })
                  .filter((price) => price !== Infinity) // Filter out invalid prices
      );

      const maxPrice = Math.max(
            ...flights
                  ?.map((flight) => {
                        // Extract the maximum price for each flight
                        return Math.max(
                              ...flight.totalPriceList
                                    .filter(
                                          (price) =>
                                                price.fd.ADULT.cc ===
                                                searchDetail?.cabinClass // Filter based on cabin class
                                    )
                                    .map(
                                          (price) =>
                                                price?.fd?.ADULT?.fC?.TF ||
                                                -Infinity // Map to TF or -Infinity if not available
                                    )
                        );
                  })
                  .filter((price) => price !== -Infinity) // Filter out invalid prices
      );

      // Handle slider change
      const handleSliderChange = (value) => {
            setRange(value);
      };

      useEffect(() => {
            setRange([minPrice, maxPrice]);
      }, [flights]);

      return (
            <>
                  <DashboardListContainer className="pt-0">
                        <DashboardListTitle>Price</DashboardListTitle>
                        <InputSliderContainer>
                              <PriceRange className="mb-3">
                                    <MinValueBox>
                                          ₹
                                          {convertAmount(
                                                convertFloat(range[0])
                                          )}{" "}
                                    </MinValueBox>
                                    <MaxValueBox>
                                          ₹
                                          {convertAmount(
                                                convertFloat(range[1])
                                          )}
                                    </MaxValueBox>
                              </PriceRange>
                              <RangeSliderInput
                                    value={range}
                                    onInput={handleSliderChange}
                                    min={minPrice}
                                    max={maxPrice}
                              />
                              <PriceRange className="mt-3">
                                    <MinValueBox>
                                          ₹
                                          {convertAmount(
                                                convertFloat(minPrice)
                                          )}
                                    </MinValueBox>
                                    <MaxValueBox>
                                          ₹
                                          {convertAmount(
                                                convertFloat(maxPrice)
                                          )}
                                    </MaxValueBox>
                              </PriceRange>
                        </InputSliderContainer>
                  </DashboardListContainer>
            </>
      );
};

export default InputSlider;
