import { Breadcrumb } from "react-bootstrap";
import styled from "styled-components";

export const CheckoutBreadcrumb = styled(Breadcrumb)`
      padding: 0 20px;
      ol {
            margin: 0;
      }
      a {
            color: ${(props) => props.theme.primary};
            font-weight: 600;
      }
`;

export const CheckoutOptionList = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      overflow: hidden;
      margin: 0 20px;
      padding: 10px 0;
`;

export const CheckoutOptionIcon = styled.div`
      padding: 10px;
      border-radius: 50rem;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      background-color: ${(props) => props.theme.white};
`;

export const CheckoutOptionLine = styled.div`
      height: 1px;
      width: auto;
      width: -webkit-fill-available;
      border-bottom: 1px dashed ${(props) => props.theme.primary};

      &.active {
            background-color: ${(props) => props.theme.primary};
      }
`;

export const CheckoutOptionInfo = styled.div`
      min-width: fit-content;

      div:first-child {
            font-size: 13px;
            text-transform: uppercase;
      }

      div:last-child {
            font-weight: 600;
      }
`;

export const CheckoutOptionContent = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;
      min-width: fit-content;
      cursor: pointer;
`;

export const CheckoutOptionItem = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;
      width: 43%;

      &:last-child {
            width: fit-content;
      }
      &.active {
            ${CheckoutOptionIcon} {
                  background-color: ${(props) => props.theme.primary};

                  svg {
                        fill: ${(props) => props.theme.white};
                  }
            }

            ${CheckoutOptionInfo} {
                  div:last-child {
                        color: ${(props) => props.theme.primary} !important;
                  }
            }
      }
`;
