import styled from "styled-components";
import { Swiper } from "swiper/react";

export const BannerSwiper = styled(Swiper)`
      height: 100%;

      .swiper-pagination {
            margin-top: 20%;
      }
`;

export const BannerImage = styled.div`
      height: 50%;
      display: flex;
      align-items: center;

      img {
            width: 100%;
            height: 280px;
            object-fit: contain;
            margin-bottom: -120px;
            position: relative;
            z-index: 9999999;
      }
`;

export const BannerTitle = styled.div`
      text-align: center;
      font-size: 20px;
      position: relative;
      height: 50%;
      background-color: ${(props) => props.theme.primary};

      .custom-shape-divider-top-1733913168 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
      }

      .custom-shape-divider-top-1733913168 svg {
            position: relative;
            display: block;
            width: calc(300% + 1.3px);
      }

      .custom-shape-divider-top-1733913168 .shape-fill {
            fill: #ffffff;
      }

      .customer__title {
            z-index: 999;
            position: relative;
            color: ${(props) => props.theme.white};
            padding: 40px;
            padding-top: 160px;
            font-size: 30px;
      }
`;
