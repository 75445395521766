import React from "react";
import { PriceAirlineDetailContainer } from "./style";
import {
      FlightAirline,
      FlightAirlineTime,
      FlightDetailAirline,
      FlightDetailInfo,
      FlightLayoverContainer,
      FlightLayoverContent,
      FlightLayoverLine,
      FlightLayoverTitle,
      FlightRefund,
} from "../../../../Dashboard/FlightDetails/style";
import { format } from "date-fns";
import { FlightInfoLogo } from "../../../../Dashboard/FlightInfo/styles";
import { BaggageIcon, TripIcon } from "../../../../../icons";
import { PriceSummary } from "../../../../../frontend/checkout/styles";
import PriceFlightModal from "../PriceFlightModal";

const PriceAirlineDetail = ({ tripInfos }) => {
      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "dd MMM");
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const calculateTimeDifference = (isoString1, isoString2) => {
            const date1 = new Date(isoString1);
            const date2 = new Date(isoString2);

            const differenceInMs = Math.abs(date1 - date2);

            const totalMinutes = Math.floor(differenceInMs / 60000);

            return calculateDuration(totalMinutes);
      };

      return (
            <>
                  {tripInfos && (
                        <PriceSummary className="mb-3">
                              <div className="price__box">
                                    <PriceAirlineDetailContainer className="pb-0 border-top-0 pt-0 mt-0">
                                          <div className="price__heading">
                                                <div className="price__title">
                                                      Departing Flight{" "}
                                                      <span>
                                                            (
                                                            {tripInfos[0]?.sI[0]
                                                                  ?.dt &&
                                                                  convertDate(
                                                                        tripInfos[0]
                                                                              ?.sI[0]
                                                                              ?.dt
                                                                  )}
                                                            )
                                                      </span>
                                                </div>
                                                <PriceFlightModal
                                                      tripInfos={tripInfos}
                                                />
                                          </div>
                                          <div>
                                                {tripInfos?.map((info, key) => (
                                                      <>
                                                            {key === 0 ? (
                                                                  ""
                                                            ) : (
                                                                  <>
                                                                        <div className="price__heading mb-2 mt-3">
                                                                              <div className="price__title">
                                                                                    Return
                                                                                    Flight{" "}
                                                                                    <span>
                                                                                          (
                                                                                          {tripInfos[
                                                                                                tripInfos?.length -
                                                                                                      1
                                                                                          ]
                                                                                                ?.sI[0]
                                                                                                ?.dt &&
                                                                                                convertDate(
                                                                                                      tripInfos[
                                                                                                            tripInfos?.length -
                                                                                                                  1
                                                                                                      ]
                                                                                                            ?.sI[0]
                                                                                                            ?.dt
                                                                                                )}

                                                                                          )
                                                                                    </span>
                                                                              </div>
                                                                        </div>
                                                                  </>
                                                            )}
                                                            <FlightDetailInfo
                                                                  marginBottom="0"
                                                                  marginTop="0"
                                                                  border="0"
                                                                  paddingTop="0"
                                                                  paddingBottom="0"
                                                            >
                                                                  {info?.sI?.map(
                                                                        (
                                                                              item,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <div>
                                                                                          <FlightDetailAirline>
                                                                                                <FlightAirline flexDirection="column">
                                                                                                      <FlightInfoLogo>
                                                                                                            <img
                                                                                                                  src={`/images/AirlinesLogo/${item?.fD?.aI?.code}.png`}
                                                                                                                  alt={
                                                                                                                        item
                                                                                                                              ?.fD
                                                                                                                              ?.aI
                                                                                                                              ?.name
                                                                                                                  }
                                                                                                            />
                                                                                                      </FlightInfoLogo>
                                                                                                      <FlightAirlineTime>
                                                                                                            <span>
                                                                                                                  {
                                                                                                                        item
                                                                                                                              ?.fD
                                                                                                                              ?.aI
                                                                                                                              ?.name
                                                                                                                  }
                                                                                                            </span>
                                                                                                      </FlightAirlineTime>
                                                                                                </FlightAirline>
                                                                                                <FlightAirlineTime>
                                                                                                      <div>
                                                                                                            {formatTime(
                                                                                                                  item?.dt
                                                                                                            )}
                                                                                                      </div>
                                                                                                      <span>
                                                                                                            {
                                                                                                                  item
                                                                                                                        ?.da
                                                                                                                        ?.city
                                                                                                            }{" "}
                                                                                                            (
                                                                                                            {
                                                                                                                  item
                                                                                                                        ?.da
                                                                                                                        ?.code
                                                                                                            }

                                                                                                            )
                                                                                                      </span>
                                                                                                </FlightAirlineTime>
                                                                                                <FlightRefund>
                                                                                                      <div className="flight__duration">
                                                                                                            {`${
                                                                                                                  calculateDuration(
                                                                                                                        item?.duration
                                                                                                                  )
                                                                                                                        .hours
                                                                                                            }h ${
                                                                                                                  calculateDuration(
                                                                                                                        item?.duration
                                                                                                                  )
                                                                                                                        .minutes
                                                                                                            }m`}
                                                                                                      </div>
                                                                                                      <div className="line"></div>
                                                                                                </FlightRefund>
                                                                                                <FlightAirlineTime>
                                                                                                      <div>
                                                                                                            {formatTime(
                                                                                                                  item?.at
                                                                                                            )}
                                                                                                      </div>
                                                                                                      <span>
                                                                                                            {
                                                                                                                  item
                                                                                                                        ?.aa
                                                                                                                        ?.city
                                                                                                            }{" "}
                                                                                                            (
                                                                                                            {
                                                                                                                  item
                                                                                                                        ?.aa
                                                                                                                        ?.code
                                                                                                            }

                                                                                                            )
                                                                                                      </span>
                                                                                                </FlightAirlineTime>
                                                                                          </FlightDetailAirline>
                                                                                          <FlightDetailInfo fontSize="14px">
                                                                                                <TripIcon
                                                                                                      width="18px"
                                                                                                      height="18px"
                                                                                                />{" "}
                                                                                                {
                                                                                                      tripInfos[0]
                                                                                                            ?.totalPriceList[0]
                                                                                                            ?.fd
                                                                                                            ?.ADULT
                                                                                                            ?.bI
                                                                                                            ?.iB
                                                                                                }
                                                                                                <BaggageIcon
                                                                                                      width="18px"
                                                                                                      height="18px"
                                                                                                      classes="ms-3"
                                                                                                />{" "}
                                                                                                {
                                                                                                      tripInfos[0]
                                                                                                            ?.totalPriceList[0]
                                                                                                            ?.fd
                                                                                                            ?.ADULT
                                                                                                            ?.bI
                                                                                                            ?.cB
                                                                                                }
                                                                                          </FlightDetailInfo>
                                                                                    </div>
                                                                                    {tripInfos
                                                                                          ?.sI
                                                                                          ?.length >
                                                                                          1 &&
                                                                                          tripInfos
                                                                                                ?.sI
                                                                                                .length !==
                                                                                                index +
                                                                                                      1 && (
                                                                                                <FlightLayoverContainer className="my-4">
                                                                                                      <FlightLayoverLine />
                                                                                                      <FlightLayoverContent>
                                                                                                            <FlightLayoverTitle
                                                                                                                  fontWeight="600"
                                                                                                                  color="#224082"
                                                                                                            >
                                                                                                                  Plane
                                                                                                                  Change
                                                                                                            </FlightLayoverTitle>
                                                                                                            <FlightLayoverTitle
                                                                                                                  fontSize="0.875rem"
                                                                                                                  color="#555"
                                                                                                            >
                                                                                                                  <span>
                                                                                                                        Layover
                                                                                                                        at
                                                                                                                        {` `}
                                                                                                                        {
                                                                                                                              item
                                                                                                                                    ?.aa
                                                                                                                                    ?.city
                                                                                                                        }

                                                                                                                        ,
                                                                                                                        {` ${
                                                                                                                              calculateTimeDifference(
                                                                                                                                    item?.at,
                                                                                                                                    tripInfos[0]
                                                                                                                                          ?.sI[
                                                                                                                                          index +
                                                                                                                                                1
                                                                                                                                    ]
                                                                                                                                          ?.dt
                                                                                                                              )
                                                                                                                                    .hours
                                                                                                                        }h ${
                                                                                                                              calculateTimeDifference(
                                                                                                                                    item?.at,
                                                                                                                                    tripInfos[0]
                                                                                                                                          ?.sI[
                                                                                                                                          index +
                                                                                                                                                1
                                                                                                                                    ]
                                                                                                                                          ?.dt
                                                                                                                              )
                                                                                                                                    .minutes
                                                                                                                        }m`}
                                                                                                                  </span>
                                                                                                            </FlightLayoverTitle>
                                                                                                      </FlightLayoverContent>
                                                                                                </FlightLayoverContainer>
                                                                                          )}
                                                                              </>
                                                                        )
                                                                  )}
                                                            </FlightDetailInfo>
                                                      </>
                                                ))}
                                          </div>
                                    </PriceAirlineDetailContainer>
                              </div>
                        </PriceSummary>
                  )}
            </>
      );
};

export default PriceAirlineDetail;
