import React from "react";

const AccountInfoIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">forum</span>
            </>
      );
};

export default AccountInfoIcon;
