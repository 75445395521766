import React from "react";
import { DashboardContainer } from "../dashboard/styles";
import { PrivacyContainer } from "../privacy/style";

const BaggagePolicy = () => {
      return (
            <>
                  <DashboardContainer>
                        <PrivacyContainer>
                              <h3>Baggage Policy</h3>
                              <p>
                                    As a registered member of Aircasa, you will
                                    be entitled to savings and benefits on
                                    airfares, and hotel reservations, among other travel services. In
                                    addition, look forward to receiving monthly
                                    newsletters and exclusive promotions
                                    offering special deals. That's why we've
                                    provided this Privacy Policy, which sets
                                    forth our policies regarding the collection,
                                    use and protection of the personal
                                    information of those using the Aircasa
                                    website. Personal information means
                                    information that can be linked to a specific
                                    individual, such as name, address, telephone
                                    number, e - mail address, and credit card
                                    number. We encourage you to review our
                                    Privacy Policy, and become familiar with it,
                                    but you should know that we do not sell or
                                    rent our customers' personal information to
                                    third parties. Please note that we review
                                    our Privacy Policy from time to time, and we
                                    may make periodic changes to the policy in
                                    connection with that review. Therefore, you
                                    may wish to bookmark this page and/or
                                    periodically review this page to make sure
                                    you have the latest version. Regardless of
                                    later updates, we will abide by the privacy
                                    practices described to you in this Privacy
                                    Policy at the time you provided us with your
                                    personal information.
                              </p>

                              <h3>
                                    With whom (if anyone) your personal
                                    information is shared?
                              </h3>
                              <p>
                                    When you reserve or purchase travel services
                                    through Aircasa PVT. LTD., we must provide
                                    certain of your personal information to the
                                    airline, and hotel, travel
                                    agency or other involved third party to
                                    enable the successful fulfillment of your
                                    travel arrangements. However, we do not sell
                                    or trade individual customer names or other
                                    personal information to third parties
                                    without the consent of the user. Aircasa
                                    uses non - personally identifiable
                                    information in aggregate form to build
                                    higher quality, more useful online services
                                    by performing statistical analysis of the
                                    collective characteristics and behavior of
                                    our customers and visitors, and by measuring
                                    demographics and interests regarding
                                    specific areas of the Aircasa website.
                                    Information such as credit card details and
                                    net banking details are usually collected
                                    directly by the Payment Gateways and Banks
                                    and not by Aircasa. These details if stored
                                    or retained by us are shared only with
                                    government/statutory/security agencies to
                                    screen for fraudulent activities and with
                                    third parties only for the purpose of Cash
                                    Back & Discounts, Chargebacks, if
                                    applicable.
                              </p>
                              <h3>
                                    What safeguards we have in place to protect
                                    your personal information?
                              </h3>
                              <p>
                                    All payments on the Aircasa site is Verisign
                                    SSL secured. This means all personal
                                    information you provide to Aircasa PVT. LTD.
                                    is transmitted using SSL (Secure Socket
                                    Layer) encryption. SSL is a proven coding
                                    system that lets your browser automatically
                                    encrypt, or scramble, data before you send
                                    it to us. The same process happens when you
                                    make travel purchases on the Aircasa site.
                              </p>
                              <h3>
                                    What other information should I know about
                                    my privacy?
                              </h3>
                              <p>
                                    Aircasa site contains links to other web
                                    sites. Please note that when you click on
                                    one of these links, you are entering another
                                    web site for which Aircasa PVT. LTD. has no
                                    responsibility. We encourage you to read the
                                    privacy statements of all such sites as
                                    their policies may be materially different
                                    from this Privacy Policy. Of course, you are
                                    solely responsible for maintaining the
                                    secrecy of your passwords, and your Aircasa
                                    site membership account information. Please
                                    be very careful, responsible, and alert with
                                    this information, especially whenever you're
                                    online. The Linked Sites are not under the
                                    control of Aircasa and Aircasa is not
                                    responsible for the contents of any Linked
                                    Site, including without limitation any link
                                    contained in a Linked Site, or any changes
                                    or updates to a Linked Site. Aircasa is not
                                    responsible for any form of transmission,
                                    whatsoever, received by the User from any
                                    Linked Site. Aircasa is providing these
                                    links to the User only as a convenience, and
                                    the inclusion of any link does not imply
                                    endorsement by Aircasa or the Website of the
                                    Linked Sites or any association with its
                                    operAircasars or owners including the legal
                                    heirs or assigns thereof. Aircasa is not
                                    responsible for any errors, omissions or
                                    representations on any Linked Site. Aircasa
                                    does not endorse any advertiser on any
                                    Linked Site in any manner. The Users are
                                    requested to verify the accuracy of all
                                    information on their own before undertaking
                                    any reliance on such information. In
                                    addition to the circumstances described
                                    above, Aircasa PVT. LTD. may disclose the
                                    Aircasa site member information if required
                                    to do so by law, court order, as requested
                                    by other government or law enforcement
                                    authority, or in the good faith belief that
                                    disclosure is otherwise necessary or
                                    advisable including, without limitation, to
                                    protect the rights or properties of Aircasa
                                    PVT. LTD. or any or all of its affiliates,
                                    associates, employees, directors or officers
                                    or when we have reason to believe that
                                    disclosing the information is necessary to
                                    identify, contact or bring legal action
                                    against someone who may be causing
                                    interference with our rights or properties,
                                    whether intentionally or otherwise, or when
                                    anyone else could be harmed by such
                                    activities. In addition, if Aircasa PVT.
                                    LTD. or substantially all of its assets are
                                    acquired, our customer information will most
                                    likely also be transferred in connection
                                    with such acquisition This policy is
                                    effective as of January 1st January 2012.
                                    Any material changes in the way Aircasa PVT.
                                    LTD. uses personal information will be
                                    described in future versions of this Privacy
                                    Statement.
                              </p>
                        </PrivacyContainer>
                  </DashboardContainer>
            </>
      );
};

export default BaggagePolicy;
