import React, { useContext } from "react";
import {
      FlightDetailCategory,
      FlightDetailContainer,
} from "../../../Dashboard/FlightDetails/style";
import { PriceSummary } from "../../../../frontend/checkout/styles";
import { FormCheck } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../../../hooks/CurrentData";
import UserContext from "../../../../context/UserContext";

const FlightReviewPayment = ({
      totalAmount,
      setWalletStatus,
      payableAmount,
      setPayableAmount,
}) => {
      const profileCtx = useContext(UserContext);

      const handleChange = (event) => {
            if (profileCtx?.profile?.balance > totalAmount) {
                  setPayableAmount(0);
            }
            setWalletStatus(event.target.checked);
      };

      return (
            <>
                  <FlightDetailContainer className="mb-3">
                        <FlightDetailCategory className="mb-4">
                              Payment Information
                        </FlightDetailCategory>
                        <PriceSummary>
                              <ul className="price__list list-inline mb-0">
                                    <li className="price__item d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Total Trip Charge
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(totalAmount)
                                                )}
                                          </div>
                                    </li>
                                    <li className="price__item d-flex align-items-center">
                                          <div className="price__item--title d-flex gap-2 w-75">
                                                <FormCheck
                                                      type="checkbox"
                                                      onChange={handleChange}
                                                />{" "}
                                                Wallet Balance
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            profileCtx?.profile
                                                                  ?.balance
                                                      )
                                                )}
                                          </div>
                                    </li>
                                    <li className="price__item d-flex align-items-center">
                                          <div className="price__item--title w-75 fw-bold">
                                                Pay Online
                                          </div>
                                          <div className="price__item--amount fw-bold">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            payableAmount
                                                      )
                                                )}
                                          </div>
                                    </li>
                              </ul>
                        </PriceSummary>
                  </FlightDetailContainer>
            </>
      );
};

export default FlightReviewPayment;
