import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import {
      BookingItemBody,
      BookingItemButton,
      BookingItemCard,
      BookingItemContainer,
      BookingItemFooter,
      BookingItemHeader,
      BookingItemPnrBox,
} from "./style";

const FlightTable = () => {
      const [loading, setLoading] = useState(false);
      const [bookings, setBookings] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings?order_type=AIR`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBookings(response.data.bookings);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BookingItemContainer>
                        {!loading ? (
                              bookings?.length > 0 ? (
                                    bookings?.map((item, index) => (
                                          <>
                                                <BookingItemCard key={index}>
                                                      <BookingItemHeader>
                                                            <div className="first__width">
                                                                  {item?.name ||
                                                                        "-"}
                                                            </div>
                                                            <div className="second__width">
                                                                  Ticket
                                                                  Confirmed
                                                            </div>
                                                            <div className="third__width"></div>
                                                      </BookingItemHeader>
                                                      <BookingItemBody>
                                                            <div className="first__width">
                                                                  1.{" "}
                                                                  {item?.name ||
                                                                        "-"}
                                                            </div>
                                                            <div className="second__width">
                                                                  <div>
                                                                        {item?.summary ||
                                                                              "-"}
                                                                  </div>
                                                                  <div>
                                                                        <div className="fw-bold">
                                                                              Booked
                                                                              On
                                                                              {
                                                                                    ": "
                                                                              }
                                                                        </div>

                                                                        <div className="text-muted">
                                                                              {item?.date ||
                                                                                    "-"}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            <div className="third__width">
                                                                  <BookingItemPnrBox>
                                                                        <div>
                                                                              <div className="fw-bold">
                                                                                    PNR
                                                                                    {
                                                                                          ": "
                                                                                    }
                                                                              </div>

                                                                              <div className="text-muted">
                                                                                    {item?.pnr_number ||
                                                                                          "-"}
                                                                              </div>
                                                                        </div>
                                                                        <div>
                                                                              <div className="fw-bold">
                                                                                    Reference
                                                                                    No
                                                                                    {
                                                                                          ": "
                                                                                    }
                                                                              </div>

                                                                              <div className="text-muted">
                                                                                    {item?.booking_id ||
                                                                                          "-"}
                                                                              </div>
                                                                        </div>
                                                                  </BookingItemPnrBox>
                                                            </div>
                                                      </BookingItemBody>
                                                      <BookingItemFooter>
                                                            <BookingItemButton>
                                                                  Invoice
                                                            </BookingItemButton>
                                                            <BookingItemButton>
                                                                  Ticket
                                                            </BookingItemButton>
                                                            <BookingItemButton className="bg-danger">
                                                                  Cancel
                                                            </BookingItemButton>
                                                            <BookingItemButton
                                                                  to={`/flights/confirmation/${item?.booking_id}`}
                                                                  target="_blank"
                                                            >
                                                                  Open
                                                            </BookingItemButton>
                                                      </BookingItemFooter>
                                                </BookingItemCard>
                                          </>
                                    ))
                              ) : (
                                    <>
                                          <div className="text-center">
                                                <p>No Bank Found</p>
                                          </div>
                                    </>
                              )
                        ) : (
                              <>
                                    <div className="text-center w-100">
                                          <Spinner />
                                    </div>
                              </>
                        )}
                  </BookingItemContainer>
            </>
      );
};

export default FlightTable;
