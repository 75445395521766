import { FlightReviewIcon, TravellerDetailIcon, WalletIcon } from "../icons";

export const stops = [
      {
            id: 1,
            title: "Stops",
            items: [
                  {
                        id: 1,
                        title: "Non-stop",
                        name: "",
                        value: 0,
                        checked: false,
                  },
                  {
                        id: 2,
                        title: "1 Stop",
                        name: "",
                        value: 0,
                        checked: false,
                  },
                  {
                        id: 3,
                        title: "1+ Stops",
                        name: "",
                        value: 0,
                        checked: false,
                  },
            ],
      },
];

export const departures = [
      {
            title: "Departure from ",
            items: [
                  {
                        id: 1,
                        title: "00 - 06",
                        image: "/images/morning_inactive.webp",
                        imageWhite: "/images/morning_inactive_white.png",
                        value: "00 - 06",
                        checked: false,
                  },
                  {
                        id: 2,
                        title: "06 - 12",
                        image: "/images/noon_inactive.png",
                        imageWhite: "/images/noon_inactive_white.png",
                        value: "06 - 12",
                        checked: false,
                  },
                  {
                        id: 3,
                        title: "12 - 18",
                        image: "/images/evening_inactive.png",
                        imageWhite: "/images/evening_inactive_white.png",
                        value: "12 - 18",
                        checked: false,
                  },
                  {
                        id: 4,
                        title: "18 - 00",
                        image: "/images/night_inactive.png",
                        imageWhite: "/images/night_inactive_white.png",
                        value: "18 - 00",
                        checked: false,
                  },
            ],
      },
];

export const arrivals = [
      {
            title: "Arrival at ",
            items: [
                  {
                        id: 1,
                        title: "00 - 06",
                        image: "/images/morning_inactive.webp",
                        imageWhite: "/images/morning_inactive_white.png",
                        value: "00 - 06",
                        checked: false,
                  },
                  {
                        id: 2,
                        title: "06 - 12",
                        image: "/images/noon_inactive.png",
                        imageWhite: "/images/noon_inactive_white.png",
                        value: "06 - 12",
                        checked: false,
                  },
                  {
                        id: 3,
                        title: "12 - 18",
                        image: "/images/evening_inactive.png",
                        imageWhite: "/images/evening_inactive_white.png",
                        value: "12 - 18",
                        checked: false,
                  },
                  {
                        id: 4,
                        title: "18 - 00",
                        image: "/images/night_inactive.png",
                        imageWhite: "/images/night_inactive_white.png",
                        value: "18 - 00",
                        checked: false,
                  },
            ],
      },
];

export const airlines = [
      {
            title: "Airlines",
            items: [
                  {
                        id: 1,
                        title: "Air India (47)",
                        name: "",
                        value: "₹5,503",
                  },
                  {
                        id: 2,
                        title: "Air India Express (10)",
                        name: "",
                        value: "₹5,558",
                  },
                  {
                        id: 3,
                        title: "Akasa Air (3)",
                        name: "",
                        value: "₹5,670",
                  },
                  {
                        id: 4,
                        title: "IndiGo (45)",
                        name: "",
                        value: "₹4,870",
                  },
                  {
                        id: 5,
                        title: "SpiceJet (5)",
                        name: "",
                        value: "₹5,401",
                  },
            ],
      },
];

export const slider = [
      {
            id: 1,
            title: "Price",
            slider: "true",
            items: [{}],
      },
];

export const flightRanges = [
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
];

export const optionInfo = [
      {
            index: 0,
            id: "travellers",
            title: "Traveller Details",
            subtitle: "First Step",
            icon: <TravellerDetailIcon />,
            status: false,
      },
      {
            index: 1,
            id: "review",
            title: "Review",
            subtitle: "Second Step",
            icon: <FlightReviewIcon />,
            status: false,
      },
      {
            index: 2,
            id: "payments",
            title: "Payments",
            subtitle: "Final Step",
            icon: <WalletIcon />,
            status: false,
      },
];

export const flightInfo = [
      {
            id: 1,
            image: "/images/logo/Indigo.png",
            title: "IndiGo",
            time: "12:30",
            leavingFrom: "DEL",
            destination: "BOM",
            arrival: "14:40",
            duration: "02h 10m",
            durationType: "Non-stop",
            price: "₹ 5,376",
      },
      {
            id: 2,
            image: "/images/logo/Indigo.png",
            title: "IndiGo",
            time: "12:30",
            leavingFrom: "DEL",
            destination: "BOM",
            arrival: "14:40",
            duration: "02h 10m",
            durationType: "Non-stop",
            price: "₹ 5,376",
      },
      {
            id: 3,
            image: "/images/logo/Indigo.png",
            title: "IndiGo",
            time: "12:30",
            leavingFrom: "DEL",
            destination: "BOM",
            arrival: "14:40",
            duration: "02h 10m",
            durationType: "Non-stop",
            price: "₹ 5,376",
      },
];

export const titleList = [
      {
            label: "Mr",
            value: "Mr",
      },
      {
            label: "Mrs",
            value: "Mrs",
      },
      {
            label: "Miss",
            value: "Ms",
      },
];

export const inFantList = [
      {
            label: "Miss",
            value: "Ms",
      },
      {
            label: "Master",
            value: "Master",
      },
];

export const countryList = [
      { label: "Afghanistan", value: "AF" },
      { label: "Albania", value: "AL" },
      { label: "Algeria", value: "DZ" },
      { label: "Andorra", value: "AD" },
      { label: "Angola", value: "AO" },
      { label: "Antigua and Barbuda", value: "AG" },
      { label: "Argentina", value: "AR" },
      { label: "Armenia", value: "AM" },
      { label: "Australia", value: "AU" },
      { label: "Austria", value: "AT" },
      { label: "Austrian Empire", value: "AT" }, // Historical reference, using "AT" for Austria
      { label: "Azerbaijan", value: "AZ" },
      { label: "Bahamas", value: "BS" },
      { label: "Bahrain", value: "BH" },
      { label: "Bangladesh", value: "BD" },
      { label: "Barbados", value: "BB" },
      { label: "Bavaria*", value: "DE" }, // Historical region in Germany, using "DE" for Germany
      { label: "Belarus", value: "BY" },
      { label: "Belgium", value: "BE" },
      { label: "Belize", value: "BZ" },
      { label: "Benin (Dahomey)", value: "BJ" }, // "Dahomey" is the former name, "BJ" is the current code
      { label: "Bolivia", value: "BO" },
      { label: "Bosnia and Herzegovina", value: "BA" },
      { label: "Botswana", value: "BW" },
      { label: "Brazil", value: "BR" },
      { label: "Brunei", value: "BN" },
      { label: "Brunswick and Lüneburg*", value: "DE" }, // Historical region in Germany, using "DE" for Germany
      { label: "Bulgaria", value: "BG" },
      { label: "Burkina Faso (Upper Volta)", value: "BF" }, // "Upper Volta" is the former name, "BF" is the current code
      { label: "Burma", value: "MM" }, // "Burma" is the former name, "MM" is the current code for Myanmar
      { label: "Burundi", value: "BI" },
      { label: "Cabo Verde", value: "CV" },
      { label: "Cambodia", value: "KH" },
      { label: "Cameroon", value: "CM" },
      { label: "Canada", value: "CA" },
      { label: "Cayman Islands", value: "KY" },
      { label: "Central African Republic", value: "CF" },
      { label: "Central American Federation*", value: "N/A" }, // Historical entity, no current code
      { label: "Chad", value: "TD" },
      { label: "Chile", value: "CL" },
      { label: "China", value: "CN" },
      { label: "Colombia", value: "CO" },
      { label: "Comoros", value: "KM" },
      { label: "Congo Free State", value: "CD" }, // Former name, now Democratic Republic of the Congo
      { label: "Costa Rica", value: "CR" },
      { label: "Cote d’Ivoire (Ivory Coast)", value: "CI" },
      { label: "Croatia", value: "HR" },
      { label: "Cuba", value: "CU" },
      { label: "Cyprus", value: "CY" },
      { label: "Czechia", value: "CZ" },
      { label: "Czechoslovakia*", value: "CZ" },
      { label: "Democratic Republic of the Congo", value: "CD" },
      { label: "Denmark", value: "DK" },
      { label: "Djibouti", value: "DJ" },
      { label: "Dominica", value: "DM" },
      { label: "Dominican Republic", value: "DO" },
      { label: "Duchy of Parma", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "East Germany (German Democratic Republic)*", value: "DE" }, // Historical entity, using "DE" for Germany
      { label: "Ecuador", value: "EC" },
      { label: "Egypt", value: "EG" },
      { label: "El Salvador", value: "SV" },
      { label: "Equatorial Guinea", value: "GQ" },
      { label: "Eritrea", value: "ER" },
      { label: "Estonia", value: "EE" },
      { label: "Eswatini", value: "SZ" },
      { label: "Ethiopia", value: "ET" },
      { label: "Federal Government of Germany (1848-49)*", value: "DE" }, // Historical entity, using "DE" for Germany
      { label: "Fiji", value: "FJ" },
      { label: "Finland", value: "FI" },
      { label: "France", value: "FR" },
      { label: "Gabon", value: "GA" },
      { label: "Gambia", value: "GM" },
      { label: "Georgia", value: "GE" },
      { label: "Germany", value: "DE" },
      { label: "Ghana", value: "GH" },
      { label: "Grand Duchy of Tuscany", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Greece", value: "GR" },
      { label: "Grenada", value: "GD" },
      { label: "Guatemala", value: "GT" },
      { label: "Guinea", value: "GN" },
      { label: "Guinea-Bissau", value: "GW" },
      { label: "Guyana", value: "GY" },
      { label: "Haiti", value: "HT" },
      { label: "Hanover", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Hanseatic Republics*", value: "DE" }, // Historical entity, using "DE" for Germany
      { label: "Hawaii", value: "US" }, // Part of the United States, using "US" for the United States
      { label: "Hesse*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Holy See", value: "VA" },
      { label: "Honduras", value: "HN" },
      { label: "Hungary", value: "HU" },
      { label: "Iceland", value: "IS" },
      { label: "India", value: "IN" },
      { label: "Indonesia", value: "ID" },
      { label: "Iran", value: "IR" },
      { label: "Iraq", value: "IQ" },
      { label: "Ireland", value: "IE" },
      { label: "Israel", value: "IL" },
      { label: "Italy", value: "IT" },
      { label: "Jamaica", value: "JM" },
      { label: "Japan", value: "JP" },
      { label: "Jordan", value: "JO" },
      { label: "Kazakhstan", value: "KZ" },
      { label: "Kenya", value: "KE" },
      { label: "Kiribati", value: "KI" },
      { label: "Korea", value: "KR" }, // South Korea, using "KR" for Korea
      { label: "Kosovo", value: "XK" }, // Unofficial ISO code for Kosovo
      { label: "Kuwait", value: "KW" },
      { label: "Kyrgyzstan", value: "KG" },
      { label: "Laos", value: "LA" },
      { label: "Latvia", value: "LV" },
      { label: "Lebanon", value: "LB" },
      { label: "Lesotho", value: "LS" },
      { label: "Lew Chew (Loochoo)*", value: "JP" }, // Historical name for Okinawa, using "JP" for Japan
      { label: "Liberia", value: "LR" },
      { label: "Libya", value: "LY" },
      { label: "Liechtenstein", value: "LI" },
      { label: "Lithuania", value: "LT" },
      { label: "Luxembourg", value: "LU" },
      { label: "Madagascar", value: "MG" },
      { label: "Malawi", value: "MW" },
      { label: "Malaysia", value: "MY" },
      { label: "Maldives", value: "MV" },
      { label: "Mali", value: "ML" },
      { label: "Malta", value: "MT" },
      { label: "Marshall Islands", value: "MH" },
      { label: "Mauritania", value: "MR" },
      { label: "Mauritius", value: "MU" },
      { label: "Mecklenburg-Schwerin*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Mecklenburg-Strelitz*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Mexico", value: "MX" },
      { label: "Micronesia", value: "FM" },
      { label: "Moldova", value: "MD" },
      { label: "Monaco", value: "MC" },
      { label: "Mongolia", value: "MN" },
      { label: "Montenegro", value: "ME" },
      { label: "Morocco", value: "MA" },
      { label: "Mozambique", value: "MZ" },
      { label: "Namibia", value: "NA" },
      { label: "Nassau*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Nauru", value: "NR" },
      { label: "Nepal", value: "NP" },
      { label: "Netherlands", value: "NL" },
      { label: "New Zealand", value: "NZ" },
      { label: "Nicaragua", value: "NI" },
      { label: "Niger", value: "NE" },
      { label: "Nigeria", value: "NG" },
      { label: "North German Confederation*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "North German Union*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "North Macedonia", value: "MK" },
      { label: "Norway", value: "NO" },
      { label: "Oldenburg*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Oman", value: "OM" },
      { label: "Orange Free State*", value: "ZA" }, // Historical region, using "ZA" for South Africa
      { label: "Pakistan", value: "PK" },
      { label: "Palau", value: "PW" },
      { label: "Panama", value: "PA" },
      { label: "Papal States*", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Papua New Guinea", value: "PG" },
      { label: "Paraguay", value: "PY" },
      { label: "Peru", value: "PE" },
      { label: "Philippines", value: "PH" },
      { label: "Piedmont-Sardinia*", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Poland", value: "PL" },
      { label: "Portugal", value: "PT" },
      { label: "Qatar", value: "QA" },
      { label: "Republic of Genoa*", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Republic of Korea (South Korea)", value: "KR" },
      { label: "Republic of the Congo", value: "CG" },
      { label: "Romania", value: "RO" },
      { label: "Russia", value: "RU" },
      { label: "Rwanda", value: "RW" },
      { label: "Saint Kitts and Nevis", value: "KN" },
      { label: "Saint Lucia", value: "LC" },
      { label: "Saint Vincent and the Grenadines", value: "VC" },
      { label: "Samoa", value: "WS" },
      { label: "San Marino", value: "SM" },
      { label: "Sao Tome and Principe", value: "ST" },
      { label: "Saudi Arabia", value: "SA" },
      { label: "Schaumburg-Lippe*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Senegal", value: "SN" },
      { label: "Serbia", value: "RS" },
      { label: "Seychelles", value: "SC" },
      { label: "Sierra Leone", value: "SL" },
      { label: "Singapore", value: "SG" },
      { label: "Slovakia", value: "SK" },
      { label: "Slovenia", value: "SI" },
      { label: "Solomon Islands", value: "SB" },
      { label: "Somalia", value: "SO" },
      { label: "South Africa", value: "ZA" },
      { label: "South Sudan", value: "SS" },
      { label: "Spain", value: "ES" },
      { label: "Sri Lanka", value: "LK" },
      { label: "Sudan", value: "SD" },
      { label: "Suriname", value: "SR" },
      { label: "Sweden", value: "SE" },
      { label: "Switzerland", value: "CH" },
      { label: "Syria", value: "SY" },
      { label: "Tajikistan", value: "TJ" },
      { label: "Tanzania", value: "TZ" },
      { label: "Texas*", value: "US" }, // Part of the United States, using "US" for the United States
      { label: "Thailand", value: "TH" },
      { label: "Timor-Leste", value: "TL" },
      { label: "Togo", value: "TG" },
      { label: "Tonga", value: "TO" },
      { label: "Trinidad and Tobago", value: "TT" },
      { label: "Tunisia", value: "TN" },
      { label: "Turkey", value: "TR" },
      { label: "Turkmenistan", value: "TM" },
      { label: "Tuvalu", value: "TV" },
      { label: "Two Sicilies*", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Uganda", value: "UG" },
      { label: "Ukraine", value: "UA" },
      { label: "Union of Soviet Socialist Republics*", value: "RU" }, // Historical region, using "RU" for Russia
      { label: "United Arab Emirates", value: "AE" },
      { label: "United Kingdom", value: "GB" },
      { label: "Uruguay", value: "UY" },
      { label: "Uzbekistan", value: "UZ" },
      { label: "Vanuatu", value: "VU" },
      { label: "Venezuela", value: "VE" },
      { label: "Vietnam", value: "VN" },
      { label: "Württemberg*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Yemen", value: "YE" },
      { label: "Zambia", value: "ZM" },
      { label: "Zimbabwe", value: "ZW" },
];

export const countryCodeList = [
      {
            label: "Afghanistan (+93)",
            value: "+93",
      },
      {
            label: "Albania (+355)",
            value: "+355",
      },
      {
            label: "Algeria (+213)",
            value: "+213",
      },
      {
            label: "Andorra (+376)",
            value: "+376",
      },
      {
            label: "Angola (+244)",
            value: "+244",
      },
      {
            label: "Antigua and Barbuda (+1-268)",
            value: "+1-268",
      },
      {
            label: "Argentina (+54)",
            value: "+54",
      },
      {
            label: "Armenia (+374)",
            value: "+374",
      },
      {
            label: "Australia (+61)",
            value: "+61",
      },
      {
            label: "Austria (+43)",
            value: "+43",
      },
      {
            label: "Azerbaijan (+994)",
            value: "+994",
      },
      {
            label: "Bahamas (+1-242)",
            value: "+1-242",
      },
      {
            label: "Bahrain (+973)",
            value: "+973",
      },
      {
            label: "Bangladesh (+880)",
            value: "+880",
      },
      {
            label: "Barbados (+1-246)",
            value: "+1-246",
      },
      {
            label: "Belarus (+375)",
            value: "+375",
      },
      {
            label: "Belgium (+32)",
            value: "+32",
      },
      {
            label: "Belize (+501)",
            value: "+501",
      },
      {
            label: "Benin (+229)",
            value: "+229",
      },
      {
            label: "Bolivia (+591)",
            value: "+591",
      },
      {
            label: "Bosnia and Herzegovina (+387)",
            value: "+387",
      },
      {
            label: "Botswana (+267)",
            value: "+267",
      },
      {
            label: "Brazil (+55)",
            value: "+55",
      },
      {
            label: "Brunei (+673)",
            value: "+673",
      },
      {
            label: "Bulgaria (+359)",
            value: "+359",
      },
      {
            label: "Burkina Faso (+226)",
            value: "+226",
      },
      {
            label: "Burma (+95)",
            value: "+95",
      },
      {
            label: "Burundi (+257)",
            value: "+257",
      },
      {
            label: "Cabo Verde (+238)",
            value: "+238",
      },
      {
            label: "Cambodia (+855)",
            value: "+855",
      },
      {
            label: "Cameroon (+237)",
            value: "+237",
      },
      {
            label: "Canada (+1)",
            value: "+1",
      },
      {
            label: "Cayman Islands (+1-345)",
            value: "+1-345",
      },
      {
            label: "Central African Republic (+236)",
            value: "+236",
      },
      {
            label: "Chad (+235)",
            value: "+235",
      },
      {
            label: "Chile (+56)",
            value: "+56",
      },
      {
            label: "China (+86)",
            value: "+86",
      },
      {
            label: "Colombia (+57)",
            value: "+57",
      },
      {
            label: "Comoros (+269)",
            value: "+269",
      },
      {
            label: "Costa Rica (+506)",
            value: "+506",
      },
      {
            label: "Cote d'Ivoire (Ivory Coast) (+225)",
            value: "+225",
      },
      {
            label: "Croatia (+385)",
            value: "+385",
      },
      {
            label: "Cuba (+53)",
            value: "+53",
      },
      {
            label: "Cyprus (+357)",
            value: "+357",
      },
      {
            label: "Czechia (+420)",
            value: "+420",
      },
      {
            label: "Democratic Republic of the Congo (+243)",
            value: "+243",
      },
      {
            label: "Denmark (+45)",
            value: "+45",
      },
      {
            label: "Djibouti (+253)",
            value: "+253",
      },
      {
            label: "Dominica (+1-767)",
            value: "+1-767",
      },
      {
            label: "Dominican Republic (+1-809, +1-829, +1-849)",
            value: "+1-809",
      },
      {
            label: "Ecuador (+593)",
            value: "+593",
      },
      {
            label: "Egypt (+20)",
            value: "+20",
      },
      {
            label: "El Salvador (+503)",
            value: "+503",
      },
      {
            label: "Equatorial Guinea (+240)",
            value: "+240",
      },
      {
            label: "Eritrea (+291)",
            value: "+291",
      },
      {
            label: "Estonia (+372)",
            value: "+372",
      },
      {
            label: "Eswatini (+268)",
            value: "+268",
      },
      {
            label: "Ethiopia (+251)",
            value: "+251",
      },
      {
            label: "Fiji (+679)",
            value: "+679",
      },
      {
            label: "Finland (+358)",
            value: "+358",
      },
      {
            label: "France (+33)",
            value: "+33",
      },
      {
            label: "Gabon (+241)",
            value: "+241",
      },
      {
            label: "Gambia (+220)",
            value: "+220",
      },
      {
            label: "Georgia (+995)",
            value: "+995",
      },
      {
            label: "Germany (+49)",
            value: "+49",
      },
      {
            label: "Ghana (+233)",
            value: "+233",
      },
      {
            label: "Greece (+30)",
            value: "+30",
      },
      {
            label: "Grenada (+1-473)",
            value: "+1-473",
      },
      {
            label: "Guatemala (+502)",
            value: "+502",
      },
      {
            label: "Guinea (+224)",
            value: "+224",
      },
      {
            label: "Guinea-Bissau (+245)",
            value: "+245",
      },
      {
            label: "Guyana (+592)",
            value: "+592",
      },
      {
            label: "Haiti (+509)",
            value: "+509",
      },
      {
            label: "Hawaii (+1-808)",
            value: "+1-808",
      },
      {
            label: "Holy See (+39)",
            value: "+39",
      },
      {
            label: "Honduras (+504)",
            value: "+504",
      },
      {
            label: "Hungary (+36)",
            value: "+36",
      },

      {
            label: "Iceland (+354)",
            value: "+354",
      },
      {
            label: "India (+91)",
            value: "+91",
      },
      {
            label: "Indonesia (+62)",
            value: "+62",
      },
      {
            label: "Iran (+98)",
            value: "+98",
      },
      {
            label: "Iraq (+964)",
            value: "+964",
      },
      {
            label: "Ireland (+353)",
            value: "+353",
      },
      {
            label: "Israel (+972)",
            value: "+972",
      },
      {
            label: "Italy (+39)",
            value: "+39",
      },
      {
            label: "Jamaica (+1-876)",
            value: "+1-876",
      },
      {
            label: "Japan (+81)",
            value: "+81",
      },
      {
            label: "Jordan (+962)",
            value: "+962",
      },
      {
            label: "Kazakhstan (+7)",
            value: "+7",
      },
      {
            label: "Kenya (+254)",
            value: "+254",
      },
      {
            label: "Kiribati (+686)",
            value: "+686",
      },
      {
            label: "North Korea (+850)",
            value: "+850",
      },
      {
            label: "South Korea (+82)",
            value: "+82",
      },
      {
            label: "Kosovo (+383)",
            value: "+383",
      },
      {
            label: "Kuwait (+965)",
            value: "+965",
      },
      {
            label: "Kyrgyzstan (+996)",
            value: "+996",
      },
      {
            label: "Laos (+856)",
            value: "+856",
      },
      {
            label: "Latvia (+371)",
            value: "+371",
      },
      {
            label: "Lebanon (+961)",
            value: "+961",
      },
      {
            label: "Lesotho (+266)",
            value: "+266",
      },
      {
            label: "Liberia (+231)",
            value: "+231",
      },
      {
            label: "Libya (+218)",
            value: "+218",
      },
      {
            label: "Liechtenstein (+423)",
            value: "+423",
      },
      {
            label: "Lithuania (+370)",
            value: "+370",
      },
      {
            label: "Luxembourg (+352)",
            value: "+352",
      },
      {
            label: "Madagascar (+261)",
            value: "+261",
      },
      {
            label: "Malawi (+265)",
            value: "+265",
      },
      {
            label: "Malaysia (+60)",
            value: "+60",
      },
      {
            label: "Maldives (+960)",
            value: "+960",
      },
      {
            label: "Mali (+223)",
            value: "+223",
      },
      {
            label: "Malta (+356)",
            value: "+356",
      },
      {
            label: "Marshall Islands (+692)",
            value: "+692",
      },
      {
            label: "Mauritania (+222)",
            value: "+222",
      },
      {
            label: "Mauritius (+230)",
            value: "+230",
      },
      {
            label: "Mexico (+52)",
            value: "+52",
      },
      {
            label: "Micronesia (+691)",
            value: "+691",
      },
      {
            label: "Moldova (+373)",
            value: "+373",
      },
      {
            label: "Monaco (+377)",
            value: "+377",
      },
      {
            label: "Mongolia (+976)",
            value: "+976",
      },
      {
            label: "Montenegro (+382)",
            value: "+382",
      },
      {
            label: "Morocco (+212)",
            value: "+212",
      },
      {
            label: "Mozambique (+258)",
            value: "+258",
      },
      {
            label: "Namibia (+264)",
            value: "+264",
      },
      {
            label: "Nauru (+674)",
            value: "+674",
      },
      {
            label: "Nepal (+977)",
            value: "+977",
      },
      {
            label: "Netherlands (+31)",
            value: "+31",
      },
      {
            label: "New Zealand (+64)",
            value: "+64",
      },
      {
            label: "Nicaragua (+505)",
            value: "+505",
      },
      {
            label: "Niger (+227)",
            value: "+227",
      },
      {
            label: "Nigeria (+234)",
            value: "+234",
      },
      {
            label: "North Macedonia (+389)",
            value: "+389",
      },
      {
            label: "Norway (+47)",
            value: "+47",
      },
      {
            label: "Oman (+968)",
            value: "+968",
      },
      {
            label: "Pakistan (+92)",
            value: "+92",
      },
      {
            label: "Palau (+680)",
            value: "+680",
      },
      {
            label: "Panama (+507)",
            value: "+507",
      },
      {
            label: "Papua New Guinea (+675)",
            value: "+675",
      },
      {
            label: "Paraguay (+595)",
            value: "+595",
      },
      {
            label: "Peru (+51)",
            value: "+51",
      },
      {
            label: "Philippines (+63)",
            value: "+63",
      },
      {
            label: "Poland (+48)",
            value: "+48",
      },
      {
            label: "Portugal (+351)",
            value: "+351",
      },
      {
            label: "Qatar (+974)",
            value: "+974",
      },
      {
            label: "Republic of Korea (South Korea) (+82)",
            value: "+82",
      },
      {
            label: "Republic of the Congo (+242)",
            value: "+242",
      },
      {
            label: "Romania (+40)",
            value: "+40",
      },
      {
            label: "Russia (+7)",
            value: "+7",
      },
      {
            label: "Rwanda (+250)",
            value: "+250",
      },
      {
            label: "Saint Kitts and Nevis (+1-869)",
            value: "+1869",
      },
      {
            label: "Saint Lucia (+1-758)",
            value: "+1758",
      },
      {
            label: "Saint Vincent and the Grenadines (+1-784)",
            value: "+1784",
      },
      {
            label: "Samoa (+685)",
            value: "+685",
      },
      {
            label: "San Marino (+378)",
            value: "+378",
      },
      {
            label: "Sao Tome and Principe (+239)",
            value: "+239",
      },
      {
            label: "Saudi Arabia (+966)",
            value: "+966",
      },
      {
            label: "Senegal (+221)",
            value: "+221",
      },
      {
            label: "Serbia (+381)",
            value: "+381",
      },
      {
            label: "Seychelles (+248)",
            value: "+248",
      },
      {
            label: "Sierra Leone (+232)",
            value: "+232",
      },
      {
            label: "Singapore (+65)",
            value: "+65",
      },
      {
            label: "Slovakia (+421)",
            value: "+421",
      },
      {
            label: "Slovenia (+386)",
            value: "+386",
      },
      {
            label: "Solomon Islands (+677)",
            value: "+677",
      },
      {
            label: "Somalia (+252)",
            value: "+252",
      },
      {
            label: "South Africa (+27)",
            value: "+27",
      },
      {
            label: "South Sudan (+211)",
            value: "+211",
      },
      {
            label: "Spain (+34)",
            value: "+34",
      },
      {
            label: "Sri Lanka (+94)",
            value: "+94",
      },
      {
            label: "Sudan (+249)",
            value: "+249",
      },
      {
            label: "Suriname (+597)",
            value: "+597",
      },
      {
            label: "Sweden (+46)",
            value: "+46",
      },
      {
            label: "Switzerland (+41)",
            value: "+41",
      },
      {
            label: "Syria (+963)",
            value: "+963",
      },
      {
            label: "Tajikistan (+992)",
            value: "+992",
      },
      {
            label: "Tanzania (+255)",
            value: "+255",
      },
      {
            label: "Thailand (+66)",
            value: "+66",
      },
      {
            label: "Timor-Leste (+670)",
            value: "+670",
      },
      {
            label: "Togo (+228)",
            value: "+228",
      },
      {
            label: "Tonga (+676)",
            value: "+676",
      },
      {
            label: "Trinidad and Tobago (+1-868)",
            value: "+1868",
      },
      {
            label: "Tunisia (+216)",
            value: "+216",
      },
      {
            label: "Turkey (+90)",
            value: "+90",
      },
      {
            label: "Turkmenistan (+993)",
            value: "+993",
      },
      {
            label: "Tuvalu (+688)",
            value: "+688",
      },
      {
            label: "Uganda (+256)",
            value: "+256",
      },
      {
            label: "Ukraine (+380)",
            value: "+380",
      },
      {
            label: "United Arab Emirates (+971)",
            value: "+971",
      },
      {
            label: "United Kingdom (+44)",
            value: "+44",
      },
      {
            label: "Uruguay (+598)",
            value: "+598",
      },
      {
            label: "Uzbekistan (+998)",
            value: "+998",
      },
      {
            label: "Vanuatu (+678)",
            value: "+678",
      },
      {
            label: "Venezuela (+58)",
            value: "+58",
      },
      {
            label: "Vietnam (+84)",
            value: "+84",
      },
      {
            label: "Württemberg*",
            value: "Württemberg*",
      },
      {
            label: "Yemen (+967)",
            value: "+967",
      },
      {
            label: "Zambia (+260)",
            value: "+260",
      },
      {
            label: "Zimbabwe (+263)",
            value: "+263",
      },
];
