import styled, { keyframes } from "styled-components";

const preloaderWraper = keyframes`
      0% {
            transform: scale(1);
      } 50% {
            transform: scale(1.2);
      } 100% {
            transform: none;
      }
`;

const preloader = keyframes`
      0% {
            transform: translateY(180%) scale(1);
      } 50% {
            transform: translateY(0%) scale(1.2);
      } 100% {
            transform: translateY(-180%);
      }
`;

const changeColorAnimation = keyframes`
  0%, 100% {
    color: #3b5384;
  }
  50% {
    color: #F29727;
  }
`;

export const PreloaderContainer = styled.div`
      background-color: white;
      position: ${(props) => props.position || "fixed"};
      z-index: 9;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      /* background-image: url(/images/banners/preloaderlinerbackground.png); */
`;

export const PreloaderWrap = styled.div`
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      border-radius: 30px;
      background-color: white;
      box-shadow: 0px 2px 24px 0px #00000014;
      animation-name: ${preloaderWraper};
      animation-iteration-count: infinite;
      animation-duration: 1.8s;
      animation-timing-function: ease;
`;

export const PreloaderIcon = styled.div`
      position: absolute;
      animation-name: ${preloader};
      animation-iteration-count: infinite;
      animation-duration: 1.8s;
      animation-timing-function: ease;
`;

export const PreloaderTitle = styled.div`
      font-size: 47px;
      font-weight: 600;
      line-height: 68px;
      letter-spacing: 0em;
      margin-top: 16px;
      /* color: #3b5384; */
      animation: ${changeColorAnimation} 1.8s ease infinite;
`;

export const PreloaderImage = styled.div`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
`;

export const heartbeat = keyframes`
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
`;

export const Logo = styled.div`
      animation: ${heartbeat} 1s infinite;

      img {
            width: 180px;
            object-fit: contain;
      }
`;
