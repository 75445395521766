import React, { useContext, useRef, useState } from "react";
import { OwnershipRegisterContainer } from "./styles";
import {
      LoginFormSection,
      LoginInput,
} from "../../../../frontend/login/styles";
import { Col, Form, FormControl, Row } from "react-bootstrap";

import {
      UserIcon,
      LockIcon,
      VisbilityIcon,
      NonVisibilityIcon,
      DateIcon,
      FemaleIcon,
      MaleIcon,
} from "../../../../icons";

import CommonButton from "../../../Common/CommonButton";
import { Link } from "react-router-dom";
import { FormContext } from "../../../../context/FormContext";

import FormCheckbox from "../../../form/FormCheckbox";

const OwnershipRegister = ({
      handleRegisterForm,
      visibleBlock,
      setDateOfBirth,
      setGender,
      gender,
      dateOfBirth,
}) => {
      const [type, setType] = useState("text");

      const [activeForm, setActiveForm] = useState("login");

      const regFormRef = useRef(null);

      const [showRegisterPassword, setShowRegsiterPassword] = useState(false);

      const { formData, setFormData } = useContext(FormContext);

      const handleLogin = () => {
            setActiveForm("login");
      };

      const handleRegisterChange = (e) => {
            const { name, value } = e.target;
            setFormData({
                  ...formData,
                  [name]: value,
            });
      };

      return (
            <>
                  <OwnershipRegisterContainer
                        className="d-flex justify-content-center"
                        show={visibleBlock === "owner"}
                  >
                        <LoginFormSection
                              onSubmit={(event) =>
                                    handleRegisterForm(event, "company")
                              }
                              ref={regFormRef}
                              style={{
                                    transform:
                                          activeForm === "register"
                                                ? "translateX(-450px)"
                                                : "translateX(0px)",
                              }}
                              className="col-lg-10"
                        >
                              <Row className="px-0 g-3">
                                    <div className="col-lg-4">
                                          <Form.Label>
                                                First Name{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </Form.Label>
                                          <LoginInput
                                                className="ps-2 "
                                                color="#f0f0f0"
                                          >
                                                <UserIcon />
                                                <FormControl
                                                      name="firstName"
                                                      type={type}
                                                      onChange={
                                                            handleRegisterChange
                                                      }
                                                      value={formData.firstName}
                                                      placeholder="First Name"
                                                      required={true}
                                                />
                                          </LoginInput>
                                    </div>
                                    <div className="col-lg-4">
                                          <Form.Label>
                                                Last Name{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </Form.Label>
                                          <LoginInput
                                                className="ps-2 "
                                                color="#f0f0f0"
                                          >
                                                <UserIcon />
                                                <FormControl
                                                      name="lastName"
                                                      value={formData.lastName}
                                                      type={type}
                                                      onChange={
                                                            handleRegisterChange
                                                      }
                                                      required={true}
                                                      placeholder="Last Name"
                                                />
                                          </LoginInput>
                                    </div>
                                    <div className="col-lg-4">
                                          <Form.Label>
                                                Gender{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </Form.Label>
                                          <div className="d-flex gap-4">
                                                <FormCheckbox
                                                      icon={<MaleIcon />}
                                                      label={"Male"}
                                                      name={"gender"}
                                                      handleChange={setGender}
                                                />
                                                <FormCheckbox
                                                      icon={<FemaleIcon />}
                                                      label={"Female"}
                                                      name={"gender"}
                                                      handleChange={setGender}
                                                />
                                          </div>
                                    </div>
                                    <Col lg={4}>
                                          <Form.Label>
                                                Date of Birth{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </Form.Label>
                                          <LoginInput
                                                className="ps-2 "
                                                color="#f0f0f0"
                                          >
                                                <DateIcon />
                                                <FormControl
                                                      type="date"
                                                      placeholder="Day"
                                                      onChange={(event) =>
                                                            setDateOfBirth(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      value={dateOfBirth}
                                                      required={true}
                                                />
                                          </LoginInput>
                                    </Col>
                                    <Col lg={4}>
                                          <Form.Label>
                                                Password{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </Form.Label>
                                          <LoginInput
                                                className="px-2 "
                                                color="#f0f0f0"
                                          >
                                                <LockIcon />
                                                <FormControl
                                                      name="password"
                                                      type={
                                                            !showRegisterPassword
                                                                  ? "password"
                                                                  : "text"
                                                      }
                                                      value={formData.password}
                                                      onChange={
                                                            handleRegisterChange
                                                      }
                                                      placeholder="Password"
                                                      required={true}
                                                />
                                                <VisbilityIcon
                                                      show={
                                                            showRegisterPassword
                                                      }
                                                      setShowPassword={
                                                            setShowRegsiterPassword
                                                      }
                                                />
                                                <NonVisibilityIcon
                                                      show={
                                                            showRegisterPassword
                                                      }
                                                      setShowPassword={
                                                            setShowRegsiterPassword
                                                      }
                                                />
                                          </LoginInput>
                                    </Col>
                                    <Col lg={4}>
                                          <Form.Label>
                                                Confirm Password{" "}
                                                <span className="text-danger">
                                                      *
                                                </span>
                                          </Form.Label>
                                          <LoginInput
                                                className="px-2 "
                                                color="#f0f0f0"
                                          >
                                                <LockIcon />
                                                <FormControl
                                                      name="confirmPassword"
                                                      type={
                                                            !showRegisterPassword
                                                                  ? "password"
                                                                  : "text"
                                                      }
                                                      value={formData.password}
                                                      onChange={
                                                            handleRegisterChange
                                                      }
                                                      required={true}
                                                      placeholder="Confirm Password"
                                                />
                                                <VisbilityIcon
                                                      show={
                                                            showRegisterPassword
                                                      }
                                                      setShowPassword={
                                                            setShowRegsiterPassword
                                                      }
                                                />
                                                <NonVisibilityIcon
                                                      show={
                                                            showRegisterPassword
                                                      }
                                                      setShowPassword={
                                                            setShowRegsiterPassword
                                                      }
                                                />
                                          </LoginInput>
                                    </Col>
                              </Row>

                              <Col lg={12} className="p-0 my-3 d-flex gap-1">
                                    <Link
                                          to="/"
                                          style={{
                                                cursor: "pointer",
                                                color: "#224082",
                                          }}
                                          onClick={handleLogin}
                                    >
                                          Login
                                    </Link>
                                    <span style={{ color: "#fff" }}>
                                          if you are already Regsiter
                                    </span>
                              </Col>
                              <Col
                                    lg={12}
                                    className="p-0 d-flex align-items-center justify-content-center gap-3"
                              >
                                    <CommonButton
                                          title={"Continue"}
                                          button={true}
                                          type={"submit"}
                                    />
                              </Col>
                        </LoginFormSection>
                  </OwnershipRegisterContainer>
            </>
      );
};

export default OwnershipRegister;
