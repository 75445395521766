import React, { useState, useEffect } from "react";
import { Col, FormLabel } from "react-bootstrap";
import Select from "react-select";

const MealItem = ({
      tripInfos,
      title,
      type,
      classes,
      destination,
      passenger,
      serviceType,
      serviceList,
      setServiceList,
      totalAmount,
      setTotalAmount,
      totalNetAmount,
      setTotalNetAmount,
}) => {
      const [options, setOptions] = useState([]);
      const [isClearable, setIsClearable] = useState(true);
      const meals = tripInfos?.ssrInfo[type];

      useEffect(() => {
            if (meals) {
                  const updateBaggages = meals.map((item) => ({
                        label: `${item.desc} ${
                              item.amount ? " @ " + item.amount : ""
                        }`,
                        value: item.code,
                  }));
                  setOptions(updateBaggages);
            }
      }, []);

      const handleServiceChange = (name, value) => {
            setServiceList((prevServices) => ({
                  ...prevServices,
                  [destination]: {
                        ...prevServices[destination],
                        [passenger]: {
                              ...prevServices[destination][passenger],
                              [serviceType]: {
                                    ...prevServices[destination][passenger][
                                          serviceType
                                    ],
                                    [name]: value,
                              },
                        },
                  },
            }));
      };

      const findingAmount = () => {
            let totalMealAmount = 0;

            if (serviceList) {
                  Object.entries(serviceList)?.forEach(
                        ([itemDestination, passengers]) => {
                              Object.entries(passengers).forEach(
                                    ([itemPassenger, services]) => {
                                          if (
                                                serviceType === "Meal" &&
                                                itemDestination ===
                                                      destination &&
                                                itemPassenger === passenger
                                          ) {
                                                totalMealAmount += parseFloat(
                                                      services.Meal.amount
                                                );
                                          }
                                          if (
                                                serviceType === "Baggage" &&
                                                itemDestination ===
                                                      destination &&
                                                itemPassenger === passenger
                                          ) {
                                                totalMealAmount += parseFloat(
                                                      services.Baggage.amount
                                                );
                                          }
                                          if (
                                                serviceType ===
                                                      "OtherService" &&
                                                itemDestination ===
                                                      destination &&
                                                itemPassenger === passenger
                                          ) {
                                                totalMealAmount += parseFloat(
                                                      services.OtherService
                                                            .amount
                                                );
                                          }
                                    }
                              );
                        }
                  );
            }

            return parseFloat(totalMealAmount);
      };

      const handleChange = (value) => {
            if (value) {
                  let selectedItem = meals.find(
                        (item) => item.code == value.value
                  );
                  console.log(value);
                  console.log(findingAmount());
                  if (selectedItem.amount) {
                        setTotalAmount(
                              parseFloat(totalAmount) +
                                    parseFloat(selectedItem.amount) -
                                    findingAmount()
                        );
                        setTotalNetAmount(
                              parseFloat(totalNetAmount) +
                                    parseFloat(selectedItem.amount) -
                                    findingAmount()
                        );
                        handleServiceChange("amount", selectedItem.amount);
                  }

                  handleServiceChange("code", selectedItem.code);
                  handleServiceChange("name", selectedItem.desc);
            } else {
                  setTotalAmount(parseFloat(totalAmount) - findingAmount());
                  setTotalNetAmount(
                        parseFloat(totalNetAmount) - findingAmount()
                  );
                  handleServiceChange("amount", 0);
                  handleServiceChange("code", "");
                  handleServiceChange("name", "");
            }
      };

      return (
            <>
                  {meals && (
                        <>
                              <Col lg={classes || 4}>
                                    <FormLabel>{title}</FormLabel>
                                    <Select
                                          options={options}
                                          onChange={handleChange}
                                          isClearable={isClearable}
                                    />
                              </Col>
                        </>
                  )}
            </>
      );
};

export default MealItem;
