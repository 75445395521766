import styled from "styled-components";

export const ComboFlightDetail = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3rem;
`;

export const ComboFlightContent = styled.div`
      width: 70%;
`;

export const ComboFlightBook = styled.div`
      width: 30%;
`;
