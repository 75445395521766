import React from "react";
import { QuestionIcon } from "../../../icons";
import { InfoModalContainer, InfoModalContent } from "./style";

const InfoModal = () => {
      return (
            <>
                  <InfoModalContainer>
                        <QuestionIcon />
                        <InfoModalContent>
                              <h4>Need Help?</h4>
                              <p>
                                    Give us a call at{" "}
                                    <a href="tel:918766601888">
                                          +91 8766601888
                                    </a>{" "}
                                    or email us at{" "}
                                    <a href="mailto:support@aircasa.in">
                                          support@aircasa.in
                                    </a>
                              </p>
                        </InfoModalContent>
                  </InfoModalContainer>
            </>
      );
};

export default InfoModal;
