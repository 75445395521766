import React, { useState } from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardNote,
      DashboardCardTable,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { InfoIcon, PrintIcon, WalletIcon } from "../../icons";
import { MenuOptionButton } from "./style";
import { Spinner } from "react-bootstrap";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import DepositFilter from "./DepositFilter";

const DepositList = () => {
      const [loading, setLoading] = useState(false);
      const [deposits, setDeposits] = useState([]);

      return (
            <>
                  <DashboardLayout title="View Deposit Request">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <WalletIcon />
                                          </MenuIcon>
                                          View Deposit Request
                                    </DashboardCardTitle>
                                    <MenuOptionButton to="/deposit-request">
                                          New Deposit Request
                                    </MenuOptionButton>
                              </DashboardCardHeader>
                              {/* <DashboardCardNote>
                                    PLEASE SELECT START & END DATE TO SEARCH
                                    DEPOSIT REQUESTS.
                              </DashboardCardNote> */}

                              <DepositFilter
                                    setDeposits={setDeposits}
                                    setLoading={setLoading}
                                    type="deposit-request"
                              />

                              <DashboardCardBody>
                                    <DashboardCardTable>
                                          <thead>
                                                <tr>
                                                      <th>S.N</th>
                                                      <th>Amount (INR)</th>
                                                      <th>Deposit Type</th>
                                                      <th>Deposit On</th>
                                                      <th>Beneficiary Bank</th>
                                                      <th>Processed Status</th>
                                                      <th>
                                                            Processed Date and
                                                            Time
                                                      </th>
                                                      <th>Processed Remarks</th>
                                                      <th>Receipt Voucher</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      deposits?.length > 0 ? (
                                                            deposits?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <tr
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <td>
                                                                                    {
                                                                                          item?.index
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    {item?.requested_amount
                                                                                          ? convertAmount(
                                                                                                  convertFloat(
                                                                                                        item?.requested_amount
                                                                                                  )
                                                                                            )
                                                                                          : "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.deposit_type ||
                                                                                          "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.date ||
                                                                                          "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.atlas_bank_name ||
                                                                                          "-"}
                                                                              </td>
                                                                              <td
                                                                                    className={`text-uppercase ${
                                                                                          item?.status ===
                                                                                          1
                                                                                                ? "text-success"
                                                                                                : item?.status ===
                                                                                                  2
                                                                                                ? "text-muted"
                                                                                                : "text-warning"
                                                                                    }`}
                                                                              >
                                                                                    {item?.status_name ||
                                                                                          "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.payment_received ||
                                                                                          "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.summary ? (
                                                                                          <InfoIcon
                                                                                                height="20px"
                                                                                                width="20px"
                                                                                          />
                                                                                    ) : (
                                                                                          "-"
                                                                                    )}
                                                                              </td>
                                                                              <td>
                                                                                    <PrintIcon
                                                                                          color="green"
                                                                                          height="20px"
                                                                                          width="20px"
                                                                                    />
                                                                              </td>
                                                                        </tr>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="100%"
                                                                              className="list-td text-center"
                                                                        >
                                                                              <p>
                                                                                    There
                                                                                    is
                                                                                    no
                                                                                    deposit
                                                                                    request
                                                                                    found.
                                                                              </p>
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan="100%"
                                                                        className="list-td text-center"
                                                                  >
                                                                        <Spinner />
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </DashboardCardTable>
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default DepositList;
