import styled from "styled-components";

export const PaymentConfirmationContainer = styled.div`
      background: white;
      padding: 60px;
      border-radius: 4px;
      box-shadow: 0 2px 3px #c8d0d8;
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      margin-top: 3rem;
      min-width: 480px;

      h1 {
            color: ${(props) => (props.status ? " #88b04b" : "#F3AE02")};
            font-weight: 900;
            font-size: 40px;
            margin-bottom: 10px;
            margin-top: 1rem;
      }
      p {
            color: #404f5e;
            font-size: 20px;
            margin: 0;
      }
      i {
            color: ${(props) => (props.status ? " #9abc66" : "#F3AE02")};
            font-size: 100px;
            line-height: 200px;
            margin-left: ${(props) => (props.status ? "-15px" : "0")};
      }
      .card__checked {
            border-radius: 200px;
            height: 200px;
            width: 200px;
            background: #f8faf5;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
      }
`;
