import React, { useState } from "react";
import {
      FlightPriceFilterContainer,
      FlightPriceFilterContent,
      FlightPriceFilterItem,
} from "../FlightPriceFilter/style";
import { BoltIcon } from "../../../icons";
import {
      calculateDuration,
      convertAmount,
      convertFloat,
} from "../../../hooks/CurrentData";

const calculateTimeDifference = (isoString1, isoString2) => {
      const date1 = new Date(isoString1);
      const date2 = new Date(isoString2);

      const differenceInMs = Math.abs(date1 - date2);

      const totalMinutes = Math.floor(differenceInMs / 60000);

      return totalMinutes;
};

const ComboFlightPriceFilter = ({
      filteredFlights,
      setFilteredFlights,
      searchDetail,
}) => {
      function groupFlights(segments, start, end) {
            const matchedFlights = [];

            segments.forEach((segment) => {
                  if (segment.da.code === start && segment.aa.code === end) {
                        matchedFlights.push(segment); // Direct flight
                  } else if (segment.da.code === start) {
                        matchedFlights.push(segment); // First leg of a multi-stop flight
                  } else if (segment.aa.code === end) {
                        matchedFlights.push(segment); // Last leg of a multi-stop flight
                  }
            });

            return matchedFlights;
      }

      const getCalculateFlightDuration = (flight) => {
            const matchedFlight = groupFlights(
                  flight?.sI,
                  searchDetail?.leavingFrom,
                  searchDetail?.destination
            );
            const matchedReturnFlight = groupFlights(
                  flight?.sI,
                  searchDetail?.destination,
                  searchDetail?.leavingFrom
            );
            const firstSegment = matchedFlight[0];
            const lastSegment = matchedFlight[matchedFlight.length - 1];
            const duration = calculateTimeDifference(
                  firstSegment?.dt,
                  lastSegment?.at
            );

            const secondSegment = matchedReturnFlight[0];
            const lastSecondSegment =
                  matchedReturnFlight[matchedReturnFlight.length - 1];
            const lastDuration = calculateTimeDifference(
                  secondSegment?.dt,
                  lastSecondSegment?.at
            );

            return duration + lastDuration;
      };

      const [currentFilter, setCurrentFilter] = useState("Cheapest");
      const fastestFlight =
            filteredFlights &&
            filteredFlights
                  ?.slice() // Make a copy of the flights array to avoid mutating the original
                  .sort((a, b) => {
                        const durationA = getCalculateFlightDuration(a); // Convert ms to minutes

                        const durationB = getCalculateFlightDuration(b);

                        return durationA - durationB; // Ascending order by total duration (fastest first)
                  });

      const cheapestFlights =
            filteredFlights &&
            filteredFlights
                  ?.slice() // Copy the flights array
                  .sort((a, b) => {
                        const priceA =
                              a.totalPriceList[0]?.fd?.ADULT?.fC?.TF || 0; // Extract the first flight price
                        const priceB =
                              b.totalPriceList[0]?.fd?.ADULT?.fC?.TF || 0; // Handle cases with undefined prices by falling back to 0

                        return priceA - priceB; // Ascending order by price
                  });

      const handleFilter = (title) => {
            if (title === "Cheapest") {
                  setFilteredFlights(cheapestFlights);
            }
            if (title === "Fastest") {
                  setFilteredFlights(fastestFlight);
            }
            setCurrentFilter(title);
      };

      const getFlightDuration = (flight) => {
            const matchedFlight = groupFlights(
                  flight?.sI,
                  searchDetail?.leavingFrom,
                  searchDetail?.destination
            );
            const matchedReturnFlight = groupFlights(
                  flight?.sI,
                  searchDetail?.destination,
                  searchDetail?.leavingFrom
            );
            const firstSegment = matchedFlight[0];
            const lastSegment = matchedFlight[matchedFlight.length - 1];
            const duration = calculateTimeDifference(
                  firstSegment?.dt,
                  lastSegment?.at
            );

            const secondSegment = matchedReturnFlight[0];
            const lastSecondSegment =
                  matchedReturnFlight[matchedReturnFlight.length - 1];
            const lastDuration = calculateTimeDifference(
                  secondSegment?.dt,
                  lastSecondSegment?.at
            );

            const finalDuration = calculateDuration(duration + lastDuration);

            return `${finalDuration.hours}h ${finalDuration.minutes}m`;
      };

      const fastestFlightPriceList = fastestFlight[0]?.totalPriceList?.filter(
            (item) => {
                  return item?.fd?.ADULT?.fC?.TF; // Ensure TF exists
            }
      );

      const minTF = Math.min(
            ...fastestFlightPriceList?.map(
                  (item) => item?.fd?.ADULT?.fC?.TF || Infinity
            )
      );

      return (
            <>
                  <FlightPriceFilterContainer>
                        <FlightPriceFilterContent>
                              <FlightPriceFilterItem
                                    className={
                                          currentFilter === "Cheapest"
                                                ? "active"
                                                : ""
                                    }
                                    onClick={() => handleFilter("Cheapest")}
                              >
                                    <div className="price__filter-icon">₹</div>
                                    <div className="price__filter-info">
                                          <div className="price__filter-title">
                                                Cheapest
                                          </div>
                                          <div className="price__filter-duration text-muted">
                                                Duration:{" "}
                                                {getFlightDuration(
                                                      cheapestFlights[0]
                                                )}
                                          </div>
                                    </div>
                              </FlightPriceFilterItem>
                              <FlightPriceFilterItem
                                    className={
                                          currentFilter === "Fastest"
                                                ? "active"
                                                : ""
                                    }
                                    onClick={() => handleFilter("Fastest")}
                              >
                                    <div className="price__filter-icon">
                                          <BoltIcon />
                                    </div>
                                    <div className="price__filter-info">
                                          <div className="price__filter-title">
                                                Fastest
                                          </div>
                                          <div className="price__filter-duration text-muted">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(minTF)
                                                )}{" "}
                                                ・ Duration:{" "}
                                                {getFlightDuration(
                                                      fastestFlight[0]
                                                )}
                                          </div>
                                    </div>
                              </FlightPriceFilterItem>
                        </FlightPriceFilterContent>
                  </FlightPriceFilterContainer>
            </>
      );
};

export default ComboFlightPriceFilter;
