import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import MealItem from "../MealItem";
import {
      TravellerAccordion,
      TravellerAccordionHeader,
} from "../TravellersDetailBox/styles";
import { TravellerAccordionBody } from "../../../../frontend/checkout/styles";
import { MealListTitle } from "./style";

const MealList = ({
      passengers,
      destination,
      destinationDetails,
      setServiceList,
      serviceList,
      totalAmount,
      setTotalAmount,
      totalNetAmount,
      setTotalNetAmount,
}) => {
      const findDestination = (currentDestination) => {
            let separateDestination = currentDestination.split("-");
            if (separateDestination?.length == 2) {
                  const newDestination = destinationDetails?.find((item) => {
                        if (
                              item?.da?.code === separateDestination[0] &&
                              item?.aa?.code
                        ) {
                              return item;
                        }
                  });
                  return newDestination;
            }
      };

      return (
            <>
                  {passengers && (
                        <>
                              {findDestination(destination)?.ssrInfo && (
                                    <TravellerAccordion defaultActiveKey="0">
                                          <Accordion.Item
                                                className="m-0"
                                                key={`${destination}`}
                                          >
                                                <TravellerAccordionHeader
                                                      fontSize="14px"
                                                      className="mb-2"
                                                >
                                                      <h3>{destination}</h3>
                                                </TravellerAccordionHeader>
                                                <TravellerAccordionBody className="m-0">
                                                      <Row className="g-4">
                                                            {Object.entries(
                                                                  passengers
                                                            )?.map(
                                                                  ([
                                                                        item,
                                                                        index,
                                                                  ]) => (
                                                                        <>
                                                                              <Col
                                                                                    lg={
                                                                                          12
                                                                                    }
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <MealListTitle>
                                                                                          {
                                                                                                item
                                                                                          }
                                                                                    </MealListTitle>
                                                                                    <Row>
                                                                                          <MealItem
                                                                                                type="MEAL"
                                                                                                title={"Meal"}
                                                                                                tripInfos={findDestination(
                                                                                                      destination
                                                                                                )}
                                                                                                destination={
                                                                                                      destination
                                                                                                }
                                                                                                passenger={
                                                                                                      item
                                                                                                }
                                                                                                setServiceList={
                                                                                                      setServiceList
                                                                                                }
                                                                                                serviceType={"Meal" }
                                                                                                serviceList={
                                                                                                      serviceList
                                                                                                }
                                                                                                totalAmount={
                                                                                                      totalAmount
                                                                                                }
                                                                                                setTotalAmount={
                                                                                                      setTotalAmount
                                                                                                }
                                                                                                totalNetAmount={
                                                                                                      totalNetAmount
                                                                                                }
                                                                                                setTotalNetAmount={
                                                                                                      setTotalNetAmount
                                                                                                }
                                                                                          />
                                                                                          <MealItem
                                                                                                type="BAGGAGE"
                                                                                                title="Baggage"
                                                                                                tripInfos={findDestination(
                                                                                                      destination
                                                                                                )}
                                                                                                destination={
                                                                                                      destination
                                                                                                }
                                                                                                passenger={
                                                                                                      item
                                                                                                }
                                                                                                setServiceList={
                                                                                                      setServiceList
                                                                                                }
                                                                                                serviceType="Baggage"
                                                                                                serviceList={
                                                                                                      serviceList
                                                                                                }
                                                                                                totalAmount={
                                                                                                      totalAmount
                                                                                                }
                                                                                                setTotalAmount={
                                                                                                      setTotalAmount
                                                                                                }
                                                                                                totalNetAmount={
                                                                                                      totalNetAmount
                                                                                                }
                                                                                                setTotalNetAmount={
                                                                                                      setTotalNetAmount
                                                                                                }
                                                                                          />
                                                                                          <MealItem
                                                                                                type="EXTRASERVICES"
                                                                                                title="Extra Services"
                                                                                                tripInfos={findDestination(
                                                                                                      destination
                                                                                                )}
                                                                                                destination={
                                                                                                      destination
                                                                                                }
                                                                                                passenger={
                                                                                                      item
                                                                                                }
                                                                                                setServiceList={
                                                                                                      setServiceList
                                                                                                }
                                                                                                serviceType="OtherService"
                                                                                                serviceList={
                                                                                                      serviceList
                                                                                                }
                                                                                                totalAmount={
                                                                                                      totalAmount
                                                                                                }
                                                                                                setTotalAmount={
                                                                                                      setTotalAmount
                                                                                                }
                                                                                                totalNetAmount={
                                                                                                      totalNetAmount
                                                                                                }
                                                                                                setTotalNetAmount={
                                                                                                      setTotalNetAmount
                                                                                                }
                                                                                          />
                                                                                    </Row>
                                                                              </Col>
                                                                        </>
                                                                  )
                                                            )}
                                                      </Row>
                                                </TravellerAccordionBody>
                                          </Accordion.Item>
                                    </TravellerAccordion>
                              )}
                        </>
                  )}
            </>
      );
};

export default MealList;
