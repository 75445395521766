import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DashboardContainer } from "../dashboard/styles";
import { PaymentConfirmationContainer } from "./style";
import axios from "axios";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import UserContext from "../../context/UserContext";

const PaymentConfirmationPage = () => {
      const { topupId } = useParams();
      const [topup, setTopup] = useState([]);
      const [loading, setLoading] = useState(false);
      const userCtx = useContext(UserContext);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/payments/topup/${topupId}`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTopup(response.data.topup);
                              userCtx.loadData();
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardContainer padding="130px" flexDirection="column">
                        <PaymentConfirmationContainer
                              status={topup?.status === "Success"}
                        >
                              <div className="card__checked">
                                    {topup?.status === "Success" ? (
                                          <i className="checkmark">✓</i>
                                    ) : (
                                          <i className="bx bx-loader"></i>
                                    )}
                              </div>
                              <h1>{topup?.status}</h1>
                              <p>
                                    {topup?.status === "Success" ? (
                                          <>
                                                Your top up request has been
                                                successfully completed.
                                                <br />₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            topup?.amount
                                                      )
                                                )}{" "}
                                                has been added on your wallet.
                                          </>
                                    ) : (
                                          <>
                                                Your top up request has been on
                                                Pending.
                                                <br /> we'll be in touch
                                                shortly!
                                          </>
                                    )}
                              </p>
                        </PaymentConfirmationContainer>
                  </DashboardContainer>
            </>
      );
};

export default PaymentConfirmationPage;
