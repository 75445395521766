import React, { useState } from "react";
import axios from "axios";
import { WalletContainer, WalletIcon, WalletInput } from "../header/styles";
import { Spinner } from "react-bootstrap";

const WalletLoad = () => {
      const [amount, setAmount] = useState(0);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleForm = async () => {
            setLoading(true);

            const callbackUrl = window.location.href;
            const redirectUrl =
                  window.location.origin + `/payments/confirmation`;

            const data = {
                  amount: amount,
                  redirectUrl: redirectUrl,
                  callbackUrl: callbackUrl,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/payment/topup`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.success === true) {
                              const redirectInfoUrl =
                                    response?.data?.data?.instrumentResponse
                                          ?.redirectInfo?.url;

                              window.location.href = redirectInfoUrl;
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <WalletContainer className=" rounded-pill">
                        <WalletInput>
                              <input
                                    type="text"
                                    placeholder="₹ 100"
                                    className="rounded-pill"
                                    onChange={(event) =>
                                          setAmount(event.target.value)
                                    }
                              />
                        </WalletInput>
                  </WalletContainer>
                  <WalletIcon
                        type="button"
                        onClick={handleForm}
                  >
                        <i className="bx bx-plus"></i> Add Money
                        {loading ? <Spinner /> : ""}
                  </WalletIcon>
            </>
      );
};

export default WalletLoad;
