import styled from "styled-components";

export const FlightModalContainer = styled.div`
      margin-bottom: 0.5rem;
      border-bottom: 1px dashed ${(props) => props.theme.primary};
      background-color: ${(props) => props.theme.primary};
      border-radius: 5px;
      padding: 1rem;
      padding-top: 1.5rem;
`;

export const ModifyFareType = styled.div`
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 20px;

      label {
            color: white;
      }
`;

export const FlightModalContent = styled.div`
      display: flex;
      align-items: center;
      gap: 1rem;
`;

export const FlightHeader = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
`;

export const FlightHeaderInfo = styled.div``;

export const FlightHeaderContent = styled.div`
      display: flex;
      gap: 10px;

      .header__content-item {
            display: flex;
            gap: 10px;
            background-color: white;
            border: 1px solid #e2e2e2;
            border-radius: 5px;
            padding: 5px 10px;
            font-size: 13px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &.active {
                  background-color: ${(props) => props.theme.primary};
                  color: ${(props) => props.theme.white};
                  border: 1px solid ${(props) => props.theme.primary};
            }
      }
`;

export const FlightModalItem = styled.div`
      border-radius: 5px;
      width: ${(props) => props.width};
      background-color: ${(props) => props.transparent || "#fff"};
      padding: ${(props) => props.padding || "10px 15px"};
      border: ${(props) => props.border || "1px solid #e2e2e2"};
      display: flex;
      text-align: left;
      cursor: pointer;
      position: relative;
      font-weight: 600;
      color: #222 !important;
      height: 46px;
`;

export const FlightModalDestination = styled.div`
      width: ${(props) => props.width || "calc(45% + 5px)"};
      height: ${(props) => props.height};
      color: #222 !important;
      position: relative;
      padding: ${(props) => props.padding || "0"};
      border: ${(props) => props.border || "0"};
      background-color: ${(props) => props.transparent || "#fff"};
      border-radius: 5px;

      input {
            padding: 0;
            border: 0;
            border-radius: 0;
            font-weight: 600;
            width: 85%;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            overflow: hidden;
            background-color: transparent;

            &:focus {
                  background-color: transparent;
            }
      }

      & > div {
            min-width: max-content;
            width: 100%;
            text-overflow: ellipsis;
      }

      span {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            overflow: hidden;
      }
`;

export const FlightModalIcon = styled.div`
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50rem;
      transition: all 0.3s ease-in-out;
      background-color: #fff;
      min-width: max-content;
      margin-top: 8px;
      z-index: 9;
      margin-left: -5px;
      margin-right: -5px;
      border: 1px solid #e2e2e2;

      &:hover,
      &.active {
            background-color: ${(props) => props.theme.primary};

            span {
                  color: #fff;
            }
      }
`;

export const FlightModalButton = styled.button`
      background-color: ${(props) => (props.status ? "#f5f5f5" : "#CCCCCC")};
      padding: 10px 15px;
      border: 0;
      border-radius: 5px;
      width: ${(props) => props.width};
      color: ${(props) =>
            props.status ? props.theme.primary : props.theme.white};
      border: 1px solid;
      font-weight: ${(props) => props.fontWeight};
      border-color: ${(props) => (props.status ? "#ccc" : props.theme.primary)};

      &:hover {
            background-color: ${(props) => props.status && "#eef4fb"};
      }

      .spinner-border {
            width: 20px !important;
            height: 20px !important;
            border-width: 1px !important;
      }
`;

export const FlightModalOptionContent = styled.div`
      position: absolute;
      left: ${(props) => (props.returnStatus ? "-117%" : 0)};
      top: 100%;
      background-color: ${(props) => props.theme.white};
      z-index: 99999;
      padding: 10px;
      margin: 0;
      min-width: max-content;
      margin-top: 10px;
      border-radius: 5px;
      border: 1px solid #dadce0;
      box-shadow: 0 0 1.25rem 0 rgba(136, 136, 136, 0.2);
`;

export const FlightModalOptions = styled.div`
      input {
            border: none;
            padding: 0;
            font-weight: 600;
            text-transform: capitalize;
            cursor: pointer;
      }
`;

export const FlightModalOption = styled.ul`
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      background-color: white;
      min-width: 220px;
      top: 100%;
      margin-top: 10px;
      left: 0;
      border: 1px solid #dadce0;
      border-radius: 5px;
      box-shadow: 0 0 1.25rem 0 rgba(136, 136, 136, 0.2);

      li {
            padding: 16px 20px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:first-child {
                  border-top-right-radius: 5px;
                  border-top-left-radius: 5px;
            }

            &:last-child {
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
            }

            &.selected {
                  background-color: #eef4fb;
            }

            &:hover {
                  background-color: #eef4fb;
            }
      }
`;

export const FlightModalInfo = styled.div`
      background-color: ${(props) => props.theme.white};
      width: 360px;
      border-radius: 5px;
      padding: 10px;
      position: absolute;
      bottom: 10%;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

      .main__title {
            color: ${(props) => props.theme.primary};
            font-size: 20px;
      }

      .main__subtitle {
            font-size: 18px;
            color: #666;
      }
`;
