import { format } from "date-fns";

const getCurrentDate = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
};
export default getCurrentDate;

export const convertDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "EEE - dd MMM yyyy");
};

export const formatTime = (isoString) => {
      const date = new Date(isoString);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
};

export const convertAmount = (amount) => {
      return parseFloat(amount).toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
      });
};

export const convertFloat = (amount) => {
      return parseFloat(amount).toFixed(2);
};

export const calculateDuration = (totalMinutes) => {
      if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
            return { hours: "00", minutes: "00" };
      }

      const hours = String(Math.floor(totalMinutes / 60)).padStart(2, "0"); // Ensures two digits for hours
      const minutes = String(totalMinutes % 60).padStart(2, "0"); // Ensures two digits for minutes

      return { hours, minutes };
};

export const calculateTimeDifference = (isoString1, isoString2) => {
      const date1 = new Date(isoString1);
      const date2 = new Date(isoString2);

      const differenceInMs = Math.abs(date1 - date2);

      const totalMinutes = Math.floor(differenceInMs / 60000);

      return calculateDuration(totalMinutes);
};

// Total Amount
export const calculateTotalAmount = (currentItem, searchDetail, fullFare) => {
      let newAmount = 0;
      if (currentItem?.ADULT) {
            newAmount =
                  newAmount +
                  currentItem?.ADULT?.fC?.TF *
                        (fullFare ? searchDetail?.adult : 1);
      }

      if (fullFare) {
            if (currentItem?.CHILD) {
                  newAmount =
                        newAmount +
                        currentItem?.CHILD?.fC?.TF *
                              (fullFare ? searchDetail?.children : 1);
            }
            if (currentItem?.INFANT) {
                  newAmount =
                        newAmount +
                        currentItem?.INFANT?.fC?.TF *
                              (fullFare ? searchDetail?.infants : 1);
            }
      }
      return newAmount;
};

export const calculateTotalNetAmount = (
      currentItem,
      searchDetail,
      fullFare
) => {
      let newAmount = 0;
      if (currentItem?.ADULT) {
            newAmount =
                  newAmount +
                  currentItem?.ADULT?.fC?.NF *
                        (fullFare ? searchDetail?.adult : 1);
      }

      if (fullFare) {
            if (currentItem?.CHILD) {
                  newAmount =
                        newAmount +
                        currentItem?.CHILD?.fC?.NF *
                              (fullFare ? searchDetail?.children : 1);
            }
            if (currentItem?.INFANT) {
                  newAmount =
                        newAmount +
                        currentItem?.INFANT?.fC?.NF *
                              (fullFare ? searchDetail?.infants : 1);
            }
      }
      return newAmount;
};
