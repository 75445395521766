import React, { useState } from "react";
import RoundtripLayout from "../RoundtripLayout";
import { RoundtripContainer } from "./style";
import RoundtripBook from "../RoundtripBook";

const RoundtripFlight = ({
      filteredFlights,
      setFilteredFlights,
      returnFilterFlights,
      setReturnFilterFlights,
      netFare,
      searchDetail,
      fullFare,
}) => {
      const [fareId, setFareId] = useState({});
      const [returnFare, setReturnFare] = useState({});

      return (
            <>
                  <>
                        <RoundtripContainer>
                              <RoundtripLayout
                                    filteredFlights={filteredFlights}
                                    setFilteredFlights={setFilteredFlights}
                                    netFare={netFare}
                                    fareId={fareId}
                                    setFareId={setFareId}
                                    fullFare={fullFare}
                                    searchDetail={searchDetail}
                              />
                              <RoundtripLayout
                                    filteredFlights={returnFilterFlights}
                                    setFilteredFlights={setReturnFilterFlights}
                                    netFare={netFare}
                                    setFareId={setReturnFare}
                                    fareId={returnFare}
                                    searchDetail={searchDetail}
                                    fullFare={fullFare}
                                    returnStatus={true}
                              />
                        </RoundtripContainer>
                        <RoundtripBook
                              fareId={fareId}
                              flights={filteredFlights}
                              returnFlights={returnFilterFlights}
                              returnFare={returnFare}
                              searchDetail={searchDetail}
                              netFare={netFare}
                              fullFare={fullFare}
                        />
                  </>
            </>
      );
};

export default RoundtripFlight;
