import { Tabs } from "react-bootstrap";
import styled from "styled-components";

export const ComboTabs = styled(Tabs)`
      border: 0;
      gap: 10px;
      margin-bottom: 8px;

      .nav-link {
            border: 1px solid #ccc;
            font-size: 12px;
            border-radius: 5px;
            padding: 5px 10px;
            color: ${(props) => props.theme.black};

            &.active {
                  border: 1px solid ${(props) => props.theme.primary};
                  background-color: ${(props) => props.theme.primary};
                  color: ${(props) => props.theme.white};
            }
      }
`;
