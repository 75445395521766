import React, { useEffect } from "react";
import { RoundtripLayoutContainer } from "./style";
import FlightLayout from "../FlightLayout";
import FlightPriceFilter from "../../../../frontend/flight/FlightPriceFilter";

const RoundtripLayout = ({
      filteredFlights,
      setFilteredFlights,
      netFare,
      fareId,
      setFareId,
      searchDetail,
      fullFare,
      returnStatus,
}) => {
      const checkCabinClassPrice = () => {
            let filteredData = filteredFlights[0]?.totalPriceList?.filter(
                  (item) => item?.fd?.ADULT?.cc === searchDetail?.cabinClass
            );

            return (
                  filteredData &&
                  filteredData.sort(
                        (a, b) => a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF
                  )
            );
      };
      useEffect(() => {
            setFareId(checkCabinClassPrice() && checkCabinClassPrice()[0]);
      }, [filteredFlights]);

      return (
            <>
                  <RoundtripLayoutContainer>
                        {filteredFlights?.length > 0 && (
                              <>
                                    <FlightPriceFilter
                                          filteredFlights={filteredFlights}
                                          searchDetail={searchDetail}
                                          setFilteredFlights={
                                                setFilteredFlights
                                          }
                                    />
                                    {filteredFlights?.map((item, index) => (
                                          <FlightLayout
                                                item={item}
                                                flights={filteredFlights}
                                                netFare={netFare}
                                                fullFare={fullFare}
                                                key={index}
                                                fareId={fareId}
                                                setFareId={setFareId}
                                                returnStatus={returnStatus}
                                                searchDetail={searchDetail}
                                          />
                                    ))}
                              </>
                        )}
                  </RoundtripLayoutContainer>
            </>
      );
};

export default RoundtripLayout;
