import React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import {
      CheckoutBreadcrumb,
      CheckoutOptionContent,
      CheckoutOptionIcon,
      CheckoutOptionInfo,
      CheckoutOptionItem,
      CheckoutOptionLine,
      CheckoutOptionList,
} from "./style";
import { Link, useNavigate, useParams } from "react-router-dom";

const CheckoutReview = ({
      options,
      setOptions,
      currentOption,
      setCurrentOption,
      reviewId,
}) => {
      const navigate = useNavigate();
      const { optionId } = useParams();

      const handleOption = (index) => {
            const updateOptions = options.map((item, i) => {
                  if (i <= index) {
                        return { ...item, status: true };
                  }
                  return { ...item, status: false };
            });
            setOptions(updateOptions);
            setCurrentOption({
                  id: updateOptions[index].id,
                  index: index,
            });
            navigate(`/flight/${updateOptions[index].id}/${reviewId}`);
      };

      const previewPage = localStorage.getItem("previousSearch");

      return (
            <>
                  <CheckoutBreadcrumb>
                        <Breadcrumb.Item>
                              <Link to={previewPage}>Flight Search</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                              active={optionId === "travellers" ? true : false}
                        >
                              {optionId === "travellers" ? (
                                    "Traveller Details"
                              ) : (
                                    <Link to={`/flight/travellers/${reviewId}`}>
                                          Traveller Details
                                    </Link>
                              )}
                        </Breadcrumb.Item>
                        {optionId === "review" && (
                              <Breadcrumb.Item active>Review</Breadcrumb.Item>
                        )}
                  </CheckoutBreadcrumb>
                  {/* <Col lg={12}>
                        <Row className="justify-content-center">
                              <Col lg={10}>
                                    <CheckoutOptionList>
                                          {options?.map((item, index) => (
                                                <>
                                                      <CheckoutOptionItem
                                                            key={index}
                                                            className={
                                                                  item?.status
                                                                        ? "active"
                                                                        : ""
                                                            }
                                                      >
                                                            <CheckoutOptionContent
                                                                  onClick={() =>
                                                                        handleOption(
                                                                              index
                                                                        )
                                                                  }
                                                            >
                                                                  <CheckoutOptionIcon>
                                                                        {
                                                                              item.icon
                                                                        }
                                                                  </CheckoutOptionIcon>
                                                                  <CheckoutOptionInfo>
                                                                        <div className="text-muted">
                                                                              {
                                                                                    item.subtitle
                                                                              }
                                                                        </div>
                                                                        <div>
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </div>
                                                                  </CheckoutOptionInfo>
                                                            </CheckoutOptionContent>
                                                            {options?.length !==
                                                                  index + 1 && (
                                                                  <CheckoutOptionLine
                                                                        className={
                                                                              index <=
                                                                              currentOption.index -
                                                                                    1
                                                                                    ? "active"
                                                                                    : ""
                                                                        }
                                                                  />
                                                            )}
                                                      </CheckoutOptionItem>
                                                </>
                                          ))}
                                    </CheckoutOptionList>
                              </Col>
                        </Row>
                  </Col> */}
            </>
      );
};

export default CheckoutReview;
