import React from "react";
import {
      DashboardListContainer,
      DashboardListItem,
      DashboardListTitle,
      DashboardListValue,
      DashboardListWrapper,
} from "../DashboardList/styles";
import { Form, Tab } from "react-bootstrap";
import { ComboTabs } from "./style";
import DepartureList from "../Departure";
import FilterDeparture from "../../Frontend/flight/FilterDeparture";

const ComboStop = ({
      lists,
      stops,
      handleClick,
      searchDetail,
      returnStops,
      handleReturnStopClick,
      departures,
      searchDetails,
      handleDepartureClick,
      leavingFromLists,
      arrivalLists,
      arrivals,
      handleArrivalClick,
      returnArrivals,
      returnDepartures,
      handleReturnArrivalClick,
      handleReturnDepartureClick,
}) => {
      return (
            <>
                  {lists?.map((item, index) => (
                        <DashboardListContainer key={index}>
                              <DashboardListTitle>
                                    {item.title}
                              </DashboardListTitle>
                              <ComboTabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                              >
                                    <Tab
                                          eventKey="home"
                                          title={`${searchDetail?.leavingFrom}-${searchDetail?.destination}`}
                                    >
                                          <DashboardListWrapper className="list-inline mb-0">
                                                {stops?.map((item, index) => (
                                                      <DashboardListItem
                                                            key={index}
                                                            className="d-flex align-items-center justify-content-between"
                                                      >
                                                            <Form.Check // prettier-ignore
                                                                  type="checkbox"
                                                                  label={
                                                                        item.title
                                                                  }
                                                                  onClick={() =>
                                                                        handleClick(
                                                                              index
                                                                        )
                                                                  }
                                                                  className="mb-0"
                                                            />
                                                            <DashboardListValue>
                                                                  {item.value}
                                                            </DashboardListValue>
                                                      </DashboardListItem>
                                                ))}
                                          </DashboardListWrapper>

                                          <DepartureList
                                                lists={leavingFromLists}
                                                departures={departures}
                                                searchDetails={searchDetails}
                                                handleClick={
                                                      handleDepartureClick
                                                }
                                          />
                                          <FilterDeparture
                                                lists={arrivalLists}
                                                searchDetails={searchDetails}
                                                arrivals={arrivals}
                                                handleClick={handleArrivalClick}
                                          />
                                    </Tab>
                                    <Tab
                                          eventKey="profile"
                                          title={`${searchDetail?.destination}-${searchDetail?.leavingFrom}`}
                                    >
                                          <DashboardListWrapper className="list-inline mb-0">
                                                {returnStops?.map(
                                                      (item, index) => (
                                                            <DashboardListItem
                                                                  key={index}
                                                                  className="d-flex align-items-center justify-content-between"
                                                            >
                                                                  <Form.Check // prettier-ignore
                                                                        type="checkbox"
                                                                        label={
                                                                              item.title
                                                                        }
                                                                        onClick={() =>
                                                                              handleReturnStopClick(
                                                                                    index
                                                                              )
                                                                        }
                                                                        className="mb-0"
                                                                  />
                                                                  <DashboardListValue>
                                                                        {
                                                                              item.value
                                                                        }
                                                                  </DashboardListValue>
                                                            </DashboardListItem>
                                                      )
                                                )}
                                          </DashboardListWrapper>
                                          <DepartureList
                                                lists={leavingFromLists}
                                                departures={returnDepartures}
                                                searchDetails={searchDetails}
                                                handleClick={
                                                      handleReturnDepartureClick
                                                }
                                          />
                                          <FilterDeparture
                                                lists={arrivalLists}
                                                searchDetails={searchDetails}
                                                arrivals={returnArrivals}
                                                handleClick={
                                                      handleReturnArrivalClick
                                                }
                                          />
                                    </Tab>
                              </ComboTabs>
                        </DashboardListContainer>
                  ))}
            </>
      );
};

export default ComboStop;
