import React, { useEffect, useState } from "react";
import { FlightModalIcon, FlightModalItem } from "../style";
import { UnoIcon } from "../../../icons";
import axios from "axios";
import FlightDestinationItem from "../FlightDestinationItem";
import FlightReturnDestinationItem from "../FlightReturnDestinationItem";

const FlightModalDepart = ({ searchDetail, modifySearch, handleChange }) => {
      const [loading, setLoading] = useState(false);
      const [destinations, setDestinations] = useState([]);
      const token = JSON.parse(localStorage.getItem("token"));
      const [unoStatus, setUnoStatus] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight_destinations/check?filter=${searchDetail?.leavingFrom},${searchDetail?.destination}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setDestinations(response.data.destinations);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleUno = () => {
            handleChange(modifySearch?.leavingFrom, "destination");
            handleChange(modifySearch?.destination, "leavingFrom");
            setUnoStatus(!unoStatus);
      };

      return (
            <>
                  <FlightModalItem
                        padding="0"
                        border="0"
                        transparent="transparent"
                        width="32%"
                  >
                        <FlightDestinationItem
                              destinations={destinations}
                              setDestinations={setDestinations}
                              handleChange={handleChange}
                              inputField="leavingFrom"
                              checkingValue={modifySearch?.destination}
                              filteredField={modifySearch?.leavingFrom}
                        />
                        <FlightModalIcon
                              onClick={handleUno}
                              className={unoStatus ? "active" : ""}
                        >
                              <UnoIcon />
                        </FlightModalIcon>
                        <FlightReturnDestinationItem
                              destinations={destinations}
                              handleChange={handleChange}
                              inputField="destination"
                              checkingValue={modifySearch?.leavingFrom}
                              filteredField={modifySearch?.destination}
                        />
                  </FlightModalItem>
            </>
      );
};

export default FlightModalDepart;
