import React, { useState } from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardTable,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { AccountStatementIcon, InfoIcon, PrintIcon } from "../../icons";
import DepositFilter from "../bank_deposits/DepositFilter";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const AccountStatement = () => {
      const [loading, setLoading] = useState(false);
      const [statements, setStatements] = useState([]);

      return (
            <>
                  <DashboardLayout title="Account Statement">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <AccountStatementIcon />
                                          </MenuIcon>
                                          Account Statement
                                    </DashboardCardTitle>
                              </DashboardCardHeader>
                              <DepositFilter
                                    setDeposits={setStatements}
                                    setLoading={setLoading}
                                    type="account-statements"
                              />
                              <DashboardCardBody padding="10px">
                                    <DashboardCardTable className="border-start">
                                          <thead>
                                                <tr>
                                                      <th>S.N</th>
                                                      <th>Date</th>
                                                      <th>Ref. No</th>
                                                      <th>Details</th>
                                                      <th>Credit</th>
                                                      <th>Debit</th>
                                                      <th>Cash Balance</th>
                                                      <th>Status</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {!loading ? (
                                                      statements?.length > 0 ? (
                                                            statements?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <tr
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <td>
                                                                                    {
                                                                                          item?.index
                                                                                    }
                                                                              </td>
                                                                              <td>
                                                                                    {item?.date ||
                                                                                          "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.type ===
                                                                                    "Debit" ? (
                                                                                          <Link
                                                                                                to={`/flights/confirmation/${item?.booking_id}`}
                                                                                                target="_blank"
                                                                                          >
                                                                                                {
                                                                                                      item?.booking_id
                                                                                                }
                                                                                          </Link>
                                                                                    ) : (
                                                                                          "-"
                                                                                    )}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.comments ||
                                                                                          "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.type ===
                                                                                    "Credit"
                                                                                          ? convertAmount(
                                                                                                  convertFloat(
                                                                                                        item?.amount
                                                                                                  )
                                                                                            )
                                                                                          : "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.type ===
                                                                                    "Debit"
                                                                                          ? convertAmount(
                                                                                                  convertFloat(
                                                                                                        item?.amount
                                                                                                  )
                                                                                            )
                                                                                          : "-"}
                                                                              </td>
                                                                              <td>
                                                                                    {item?.available_balance
                                                                                          ? convertAmount(
                                                                                                  convertFloat(
                                                                                                        item?.available_balance
                                                                                                  )
                                                                                            )
                                                                                          : "-"}
                                                                              </td>
                                                                              <td
                                                                                    className={` ${
                                                                                          item?.status ===
                                                                                          "Success"
                                                                                                ? "text-dark"
                                                                                                : item?.status ===
                                                                                                  2
                                                                                                ? "text-muted"
                                                                                                : "text-danger"
                                                                                    }`}
                                                                              >
                                                                                    {item?.status ||
                                                                                          "-"}
                                                                              </td>
                                                                        </tr>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan="100%"
                                                                              className="list-td text-center"
                                                                        >
                                                                              <p>
                                                                                    There
                                                                                    is
                                                                                    no
                                                                                    deposit
                                                                                    request
                                                                                    found.
                                                                              </p>
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan="100%"
                                                                        className="list-td text-center"
                                                                  >
                                                                        <Spinner />
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )}
                                          </tbody>
                                    </DashboardCardTable>
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default AccountStatement;
